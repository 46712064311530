import React from "react";
import ErrorForm from "../form/Error";
import useRepairNew from "../../hooks/useRepairNew";
import InputFormik from "../form/InputFormik";
import TextAreaFormik from "../form/textareaFormik";
import useBreakdownRepair from "../../hooks/useBreakdownRepair";
import useCheckout from "../../hooks/useCheckout";
const FormCheckout = () => {
  const {
    formik,
    mvtTypeData,
    loadingMvtType,
    errorMvtType,
    loading
  } = useCheckout();

  return (
      <form onSubmit={formik.handleSubmit} className="relative flex flex-col gap-8">
          {/*{loadingMvtType && (*/}
          {/*    <div className="text-white/60">*/}
          {/*        Chargement des types de mouvement ...*/}
          {/*    </div>*/}
          {/*)}*/}

          {mvtTypeData?.data?.length !== 0 && (
              <div>
                  <div className="flex-col w-full flex text-white border-b-2 border-solid border-white">
                      <label
                          className="text-white/60 relatve text-[0.75em]  pointer-events-none"
                          htmlFor="type-v"
                      >
                          Composant
                      </label>
                      <select
                          value={formik.values.type}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="w-full focus:outline-none  bg-transparent"
                          name="type">
                          <option
                              hidden={true}
                              className="text-black"
                              value="0">
                              Sélectionnez le motif du départ
                          </option>
                          {mvtTypeData?.data?.map((item, index) => {
                              return (
                                  <option
                                      key={index}
                                      className="text-black"
                                      value={item?.id}>
                                      {item?.label}
                                  </option>
                              )
                          })}
                      </select>
                  </div>
                  {formik.touched.type && formik.errors.type && (
                      <ErrorForm message={formik.errors.type}/>
                  )}
              </div>
          )}

          <div className="w-full">
              <InputFormik
                  label="Kilométrage a la date"
                  placeholder=""
                  name="mileage"
                  value={formik.values.mileage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
              />
              {formik.touched.mileage && formik.errors.mileage && (
                  <ErrorForm message={formik.errors.mileage}/>
              )}
          </div>

          <div
              className={["w-full active:bg-secondary p-2 text-center rounded mb-5 text-white", loading ? "bg-gray-400" : "hover:bg-[#2dcf34] bg-secondary"].join(" ")}>
              <button
                  className="focus:outline-none uppercase w-full"
                  type="submit"
                  disabled={loading}>
                  {loading ? "chargement..." : "enregistrer"}
              </button>
          </div>
      </form>
  );
};

export default FormCheckout;
