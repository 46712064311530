import { useState,useEffect } from "react";
import {BiChevronDown, BiChevronUp} from "react-icons/bi"

function Dropdown(props) {

    const [isOpen, setIsOpen] = useState(false)
    // const [selected, setSelected] = useState("")
    useEffect(()=>{
         props.formik.setFieldValue(props.input,props.selected)
    },[props.selected])

    return (
        <div className="flex flex-col items-center font-body">
            <div onClick={()=> setIsOpen((prev) => !prev)} className='inputForm flex items-center justify-between'>
                <p className={` ${props.selected.indexOf(' ') ? 'text-grayInput' : 'text-white'}`}>{props.selected? props.selected: `${props.placeholder}`}</p>
                {!isOpen ? (
                    <BiChevronDown size={20}/>
                ) : (
                    <BiChevronUp size={20}/>
                )}
            </div>

            {isOpen && (
                <ul className={`bg-primary top-8 flex flex-col items-start p-2 w-full border-y-[1px] border-l-[1px] border-hoverBleu-100 shadow-lg  max-h-32 overflow-y-auto `}>
                    {props.options.map((item, i)=> (
                        <li onClick={()=> {
                            if(item?.toLowerCase() !== props.selected.toLowerCase()){
                                props.setSelected(item)
                                setIsOpen((prev) => !prev)
                               
                            }
                        }} className="flex w-full justify-start hover:bg-hoverBleu-100 cursor-pointer text-white py-2" key={i}>
                            {item}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default Dropdown