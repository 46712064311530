// import InputArea from "../../components/form/InputArea";
// import useLoginSubmit from "../../hooks/useLoginSubmit";
// import { useTranslation } from "react-i18next";
// import { FORGOT_PASSWORD, REGISTER } from "../../pref";
// import LoginForm from "../../components/auth/LoginForm";
// import { useNavigate } from "react-router-dom";
import ErrorForm from "../form/Error";
import React from "react";
import useForgotPassword from "../../hooks/useForgotPassword";
const FormeForgotPassword = () => {
  const { formik, loading } = useForgotPassword();

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="max-w-lg  px-6 w-full flex flex-col items-center justify-center"
    >
      <div className="w-full mt-6">
        <input
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name={"password"}
          placeholder="Nouveau mot de passe"
          className="inputForm"
        />
        {formik.touched.password && formik.errors.password && (
          <ErrorForm message={formik.errors.password} />
        )}
      </div>
      <div className="w-full mt-6">
        <input
          type="password"
          value={formik.values.passwordConfirmation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name={"passwordConfirmation"}
          placeholder="Confirmer votre  mot de passe"
          className="inputForm"
        />
        {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
          <ErrorForm message={formik.errors.passwordConfirmation} />
        )}
      </div>
      <button disabled={loading} type="submit" className="buttonForm mt-16 focus:outline-none">
        CHANGER DE MOT DE PASSE
      </button>
    </form>
  );
};

export default FormeForgotPassword;
