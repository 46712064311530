import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {LiaArrowLeftSolid} from "react-icons/lia";
import {useSelector} from "react-redux";
import {selectedCar} from "../../utils";
import Skeleton from "react-loading-skeleton";
import dayjs from "dayjs";
import ReparationServices from "../../services/ReparationServices";
import {HiBars3} from "react-icons/hi2";
import {CLIENT} from "../../pref";

const ReparationDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedReparation = location?.state?.reparation;

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const [reparationsListData, setReparationsListData] = useState([] || {});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  // console.log("id params", id)

  const user = useSelector((state) => state.authSlice.userInfo);

  useEffect(() => {
    if(selectedReparation === null || selectedReparation === undefined){
      navigate("/")
    }else{
      loadReparationList()
    }
  }, [selectedReparation]);

  function loadReparationList () {
    setError("")
    setLoading(true)
    ReparationServices
      .getCarReparationList(selectedReparation?.id, car?.id)
      .then(res => setReparationsListData(res?.data))
      .catch(err => setError(err.message))
      .finally(()=> setLoading(false))
  }

  function elementTitle(components){
    let title = "";
    components.forEach((elt, index) => {
      if(elt.component_type === "registered"){
        if(title === ""){
          title = elt?.component?.label + ", "
        }else{
          if(index === components.length - 1){
            title = title + elt?.component?.label
          }else{
            title = title + elt?.component?.label + ", "
          }
        }
      }
    })

    return title;
  }

  function priceCal(components){
    let price = 0;
    components.forEach(elt => {
      price = price + elt.amount;
    })

    return price;
  }

  return (
      <>
        <div className="flex  items-center py-3   bg-primary">
          <div
            onClick={() => navigate(-1)}
            className="pl-4 pr-8 cursor-pointer"
          >
            <LiaArrowLeftSolid className="text-white text-2xl"/>
          </div>
          <div className="font-myfont text-base md:text-lg text-white">
            Dernière opération
          </div>
        </div>

        {loading && (
          <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-6 px-6 my-4">
            {[1, 2].map(item => {
              return (
                <div key={item}>
                  <Skeleton
                    count={4}
                    height={140}
                    className="bg-gray-600"
                  />
                </div>
              )
            })}
          </div>
        )}

        {(!loading && error.length !== 0) && (
          <div className="flex-1 h-full flex flex-col justify-center items-center text-red-500 gap-4 my-4">
            <div className="text-center">Une erreur est survenu veuillez réessayer</div>
            <button
                onClick={loadReparationList}
                className="uppercase px-8 py-1.5 rounded hover:bg-red-500/10 active:bg-red-500/20 focus:outline-none border-2 border-red-500">
              réessayer
            </button>
          </div>
        )}

        {(!loading && error.length === 0 && reparationsListData?.data?.length !== 0) && (
            <div className="bg-white flex-1 flex flex-col justify-start items-center overflow-y-auto">
              <div className="w-full flex flex-col px-3 mt-2 mb-4 justify-center items-center">
                <div className="px-2 flex flex-col border-b-2 border-gray-300 w-full py-2 ">
                  <div className="text-gray-500 font-semibold">
                    {reparationsListData?.data[0]?.system_component?.system?.label}
                  </div>
                  <div className="flex gap-3">
                    <div className="text-sm">
                      Depense total :
                    </div>
                    <div className="text-sm text-black font-bold uppercase">
                      {`${reparationsListData?.data?.reduce((accumulator, item) => {
                        return accumulator + item.price + item.service_cost;
                      }, 0)} fcfa`}
                    </div>
                  </div>
                </div>

                <div className="mt-4 w-full grid grid-cols-1 lg:grid-cols-2 gap-3">
                  {reparationsListData?.data?.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className="w-full flex card bg-white lg:max-w-[450px] h-fit flex-wrap shadow-lg border">
                          <div className="w-full mt-4 px-2 flex flex-col gap-1">
                            <div className="w-full flex flex-row gap-3">
                              <div className='flex-1 text-sm lg:text-base'>
                                Date :
                              </div>
                              <div className='flex-none'>
                                {dayjs(item?.data).format("DD MMM YYYY")}
                              </div>
                            </div>

                            <div className="w-full flex flex-row gap-3">
                              <div className='flex-none text-sm lg:text-base'>
                                Remplacement de :
                              </div>
                              <div className='flex-1'>
                                {elementTitle(item?.components)}
                              </div>
                            </div>

                            {item?.components.length === 1 && (
                              <div className="w-full flex flex-row gap-3">
                                <div className='flex-1 text-sm lg:text-base'>
                                  Prix de la piece :
                                </div>
                                <div className='flex-none lowercase'>
                                  {`${item?.components[0]?.component ? item?.component?.components[0]?.label : item?.components[0]?.component_type} (${item?.components[0]?.amount} fcfa)`}
                                </div>
                              </div>
                            )}

                            {(item?.components.length > 1 && (
                              <div className="w-full flex flex-row gap-3">
                                <div className='flex-1 text-sm lg:text-base'>
                                  Prix de(s) piece(s) :
                                </div>
                                <div className='flex-none flex flex-col lowercase'>
                                  {item?.components.map((elt, index) => {
                                    return (
                                      <div key={index.toString()} className="flex flex-row gap-2 items-center">
                                        <div className="w-2 h-2 rounded-full bg-black"/>
                                        <div>{`${elt?.component ? elt?.component?.label : elt?.component_type} (${elt?.amount} fcfa)`}</div>
                                      </div>
                                    )
                                  })}
                                  {/*{`${priceCal(item?.components)} fcfa`}*/}
                                </div>
                              </div>
                            ))}

                            <div className="w-full flex flex-row gap-3">
                              <div className='flex-1 text-sm lg:text-base'>
                                Prix de la main d'oeuvre :
                              </div>
                              <div className='flex-none uppercase'>
                                {`${item?.service_cost} fcfa`}
                              </div>
                            </div>
                          </div>
                          <div className="w-full flex justify-end mt-6">
                            <div className="flex items-center gap-4">
                              <div className="text-sm text-gray-700">
                                Prix :
                              </div>
                              <div className="bg-primary py-1 px-6 ml-4 text-white uppercase">
                                {`${item?.price + item?.service_cost} fcfa`}
                              </div>
                            </div>
                          </div>
                        </div>
                    )
                  })}
                </div>

              </div>
            </div>
        )}

        {(!loading && error.length === 0 && reparationsListData?.data?.length === 0) && (
            <div className="bg-white flex-1 flex flex-col justify-center items-center overflow-y-auto">
              <div className="w-full flex flex-col gap-2 px-3 mt-2 mb-4 text-center text-gray-500 justify-center items-center">
                <HiBars3 className="text-[150px] text-gray-300"/>
                <div className="max-w-md font-semibold">
                  Vous n'avez aucune réparation enregistrée
                  {/*pour transmission et pneumatique*/}
                </div>
              </div>
            </div>
        )}

        {user?.data?.roles[0]?.name === CLIENT && (
            <button
                className="flex justify-center items-center py-3 bg-primary text-white hover:bg-hoverBleu-200 active:bg-primary"
                onClick={() => navigate("/reparation_new", {
                    replace: false,
                    state: {
                        reparation: selectedReparation,
                    }
                })}
            >
                Enregistrer une Réparation
            </button>
        )}
      </>
  );
};

export default ReparationDetail;
