import DepartureArrival from "../pages/menu/departure_arrival";
import Setting from "../pages/menu/Setting";
import CheckOut from "../pages/form/check_out";
import CheckIn from "../pages/form/check_in";

const routesEmployee = [
  {
    path: "/",
    component: DepartureArrival,
  },
  {
    path: "/setting",
    component: Setting,
  },
  {
    path: "/check_out",
    component: CheckOut,
  },
  {
    path: "/check_in",
    component: CheckIn,
  },
];

export default routesEmployee;
