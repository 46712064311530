import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import {useSelector} from "react-redux";
import {selectedCar} from "../../utils";
import {CLIENT} from "../../pref";

const PrivateRoute = () => {
  const user = useSelector((state) => state.authSlice.userInfo);

  return user?.token ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" />
  );
};

export default PrivateRoute;
