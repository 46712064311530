import React from "react";
import FormOperation from "../../components/auth/FormOperationNew";
import Main from "../../layout/Main";
import {useNavigate} from "react-router-dom";
import FormBreakdownNew from "../../components/auth/FormBreakdownNew";
import FormCheckout from "../../components/auth/FormCheckout";
import FormCheckin from "../../components/auth/FormCheckin";

const CheckIn = () => {
    const navigate = useNavigate();

    return (
        <Main selected="empty">
            <div className="flex-1 w-full h-full overflow-y-auto bg-primary flex justify-center border-t-2">
                <div className="max-w-[550px] w-full pb-4">
                    <div className="mt-16 text-center">
                        <div className="uppercase text-[#FFFFFF] font-bold">
                            enregistrer une arrivée
                        </div>
                        <div className="text-white/40">Veuillez remplir ce formulaire</div>
                    </div>
                    <div className="mt-16">
                        <FormCheckin/>
                    </div>
                    <button
                        onClick={() => {
                            navigate(-1);
                        }}
                        className="inputSecondary"
                        type="button">
                        annuler
                    </button>
                </div>
            </div>
        </Main>
    );
};

export default CheckIn;
