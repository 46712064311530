import requests from "./httpService";

const AuthServices = {
  login: async (body) => {
    return requests.post(`/auth/login`, body);
  },

  register: async (body) => {
    return requests.post(`/auth/register`, body);
  },

  addUsers: async (body) => {
    return requests.post(`/users`, body);
  },

  verifyNumber: async (phone) => {
    return requests.get(`/auth/verify-number/+237${phone}`);
  },

  verifyOtp: async (body) => {
    return requests.post(`auth/confirm/phone`, body);
  },

  sendOtp: async (body) => {
    return requests.post(`auth/send/otp`, body);
  },

  getAllDriver: async (id) => {
    return requests.get(`users/clients/${id}`);
  },

  getAllRoles: async () => {
    return requests.get(`roles/clients/all`);
  },
};

export default AuthServices;
