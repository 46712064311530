import { useNavigate } from "react-router-dom";
import Main from "../../layout/Main";
import React, {useEffect, useState} from "react";
import {MdAdd, MdOutlineMenu, MdOutlineTimer} from "react-icons/md";
import {FaRegCalendarAlt} from "react-icons/fa";
import {useSelector} from "react-redux";
import {selectedCar} from "../../utils";
import DocumentService from "../../services/DocumentService";
import dayjs from "dayjs";
import {dateDifference} from "../../utils/date";
import Skeleton from "react-loading-skeleton";
import {CLIENT} from "../../pref";

const Legislation = () => {
    const navigate = useNavigate();

    const cars = useSelector((state) => state.carManagementSlice.data);
    const car = selectedCar(cars)

    const user = useSelector((state) => state.authSlice.userInfo);

    const [documentsData, setDocumentsData] = useState([] || {});
    const [error, setError] = useState("");
    const [loadingDocuments, setLoadingDocuments] = useState(false);

    useEffect(() => {
        if(car !== null){
            loadDocuments()
        }
    }, [car]);

    function loadDocuments () {
        setError("")
        setLoadingDocuments(true)
        DocumentService
            .getCarDocument(car?.id)
            .then(res => setDocumentsData(res?.data))
            .catch(err => setError(err.message))
            .finally(()=> setLoadingDocuments(false))
    }

    function getValidity(expireDate) {
        return  dateDifference(new Date(), expireDate)
    }

    return (
        <Main selected="Legislation">
            <div className="flex-1 w-full h-full overflow-y-auto">
                <div className="w-full flex text-white justify-center flex-col px-2 md:px-6">
                    <div className="flex-none text-center my-2 p-2 md:p-4 rounded-lg bg-white shadow-lg border-gray-300 border">
                        <div className="uppercase text-primary font-bold text-md md:text-xl">
                            Gestion administrative
                        </div>
                        <p className="text-gray-500 text-sm md:text-base">
                            Enregistrez les documents administratifs du véhicule avec la date de création et
                            d'expiration. Le système saura vous rappeler les différentes dates de renouvellement
                        </p>
                    </div>
                </div>

                {loadingDocuments && (
                    <div className="cards grid grid-cols-1 md:grid-cols-2 gap-6 px-3 md:px-6">
                        {[1, 2].map(item => {
                            return (
                                <div key={item}>
                                    <Skeleton
                                        count={2}
                                        height={250}
                                        className="bg-gray-600"
                                    />
                                </div>
                            )
                        })}
                    </div>
                )}

                {(!loadingDocuments && error.length !== 0) && (
                    <div
                        className="flex-1 h-full flex flex-col gap-4 justify-center items-center font-semibold text-red-500 px-4">
                        <div className="text-center">Une erreur est survenu veuillez réessayer</div>
                        <button
                            onClick={loadDocuments}
                            className="uppercase px-8 py-1.5 rounded hover:bg-red-500/10 active:bg-red-500/20 focus:outline-none border-2 border-red-500">
                            réessayer
                        </button>
                    </div>
                )}

                {(!loadingDocuments && error.length === 0) && (
                    <div className="cards grid grid-cols-1 md:grid-cols-2 gap-3 px-3 md:px-6 mt-4 mb-8">
                        {documentsData?.data?.map(document => {
                            if (document?.last_valids === null) {
                                return (
                                    <div className="h-fuull flex flex-col bg-white shadow-xl">

                                        <div className="flex-1 my-16 h-full flex flex-col gap-3 justify-center items-center">
                                            <MdOutlineMenu className="text-6xl text-gray-300"/>
                                            <p className="text-gray-500">
                                                vous n'avez aucune {document?.label?.toLowerCase()}
                                            </p>
                                        </div>

                                        {user?.data?.roles[0]?.name === CLIENT && (
                                            <button
                                                onClick={() => navigate("/document_new", {
                                                    replace: false,
                                                    state: {
                                                        document: document
                                                    },
                                                })}
                                                className="flex-none w-full flex items-center text-sm md:text-base font-myfont justify-center p-3 bg-primary text-white hover:bg-hoverBleu-200 active:bg-primary">
                                                Enregistrer une {document?.label?.toLowerCase()}
                                            </button>
                                        )}
                                    </div>
                                )
                            }

                            return (
                                <div className="bg-white shadow-xl min-h-80 h-hull">
                                    <div
                                        className="flex justify-between items-center bg-primary text-white px-3 h-12">
                                        <p className="font-myfont">{document?.label}</p>
                                        {(user?.data?.roles[0]?.name === CLIENT && getValidity(document?.last_valids?.end_date) < 0) && (
                                            <button
                                                onClick={() => navigate("/document_new", {
                                                    replace: false,
                                                    state: {
                                                        document: document
                                                    },
                                                })}
                                                className="hover p-2 hover:bg-black/20 focus:outline-none active:bg-black/40">
                                                <MdAdd/>
                                            </button>
                                        )}
                                    </div>

                                    <div className="px-3 py-5 flex flex-col gap-16 ">
                                        <div className="flex flex-col gap-2">
                                            <div className="flex gap-3 items-center">
                                                <FaRegCalendarAlt className="text-primary text-xl"/>
                                                <div className="text-primary flex flex-col ">
                                                    <p className="font-bold text-lg">Souscription</p>
                                                    <p className="text-gray-500 text-base">
                                                        {dayjs(document?.last_valids?.start_date).format("DD MMM YYYY")}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="flex gap-3 items-center">
                                                <FaRegCalendarAlt className="text-xl text-transparent"/>
                                                <div className="text-primary flex flex-col ">
                                                    <p className="font-bold text-lg">Expiration</p>
                                                    <p className="text-gray-500 text-base">
                                                        {dayjs(document?.last_valids?.end_date).format("DD MMM YYYY")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {getValidity(document?.last_valids?.end_date) < 0 ? (
                                            <div
                                                className="border-2 border-red-600 text-red-600 flex justify-center py-2 font-semibold uppercase">
                                                expiré
                                            </div>
                                        ) : (
                                            <div className="flex gap-3 items-center">
                                                <MdOutlineTimer className="text-primary text-xl"/>
                                                <div className="text-primary flex-col ">
                                                    <p className="font-bold text-lg">Durée de validation</p>
                                                    <p className="text-gray-500 text-base">
                                                        {parseInt(getValidity(document?.last_valids?.end_date))} {`${parseInt(getValidity(document?.last_valids?.end_date)) == 1 ? "jour" : "jours"}`}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </Main>
    )
}

export default Legislation;