import React from "react";
import { useTranslation } from "react-i18next";
import {useFormik} from "formik";
import * as yup from "yup";
import ErrorForm from "../form/Error";
import InputFormik from "../form/InputFormik";

const DeleteCarDialog = ({
    closeModal,
    onSubmit,
    loading,
    car
}) => {
    const { t } = useTranslation();

    const initialValues = {
        password: ""
    };

    const validationSchema = yup.object().shape({
        password: yup.string().required("Le mot de passe est obligatoire")
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    });

    return (
        <form onSubmit={formik.handleSubmit} className="gap-8 p-6">
            <div className="flex flex-col gap-2">
                <div className="font-bold text-lg">Suppression du véhicle</div>
                <div className="text-base">
                    Vous etes sur le point de vouloir supprimer le vehicule :
                    <span className="font-bold">{` ${car?.brand?.label} ${car?.model} - ${car?.plate_number}`}</span>
                </div>
                <div>
                    NB: Cette action est irreversible
                </div>
            </div>
            <div className="mt-4">
                {/*<div className="w-full">*/}
                {/*    <div className="border-b-[1px]">*/}
                {/*        <InputFormik*/}
                {/*            label="Mot de passe"*/}
                {/*            placeholder=""*/}
                {/*            name="password"*/}
                {/*            value={formik.values.password}*/}
                {/*            onChange={formik.handleChange}*/}
                {/*            onBlur={formik.handleBlur}*/}
                {/*            inputColor="black"*/}
                {/*            inputLabel="#000000"*/}
                {/*            type="password"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    {formik.touched.password && formik.errors.password && (*/}
                {/*        <ErrorForm message={formik.errors.password}/>*/}
                {/*    )}*/}
                {/*</div>*/}
                <div className="mt-8 flex justify-between text-lg font-semibold">
                    <button
                        onClick={closeModal}
                        type="button"
                        className="px-4 py-2 rounded focus:outline-none text-primary hover:bg-primary/20 active:bg-primary active:text-white">
                        Annuler
                    </button>
                    <button
                        disabled={loading}
                        type="button"
                        onClick={onSubmit}
                        className={["px-4 py-2 rounded focus:outline-none", loading ? "bg-transparent text-secondary hover:bg-transparent" : "text-red-500 hover:bg-red-500/20 active:bg-red-500 active:text-white"].join(" ")}>
                        {loading ? "Chargement..." : "Supprimer"}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default DeleteCarDialog;
