import requests from "./httpService";

const MaintenanceServices = {
  getCarOperation: async (id) => {
    return requests.get(`/operation_statuses/vehicle/${id}`);
  },

  getCarOperationList: async (operationId, carId) => {
    return requests.get(`/maintenances/vehicle/${operationId}/${carId}`);
  },

  addCarOperation: async (body) => {
    return requests.post(`/maintenances`, body);
  },
};
export default MaintenanceServices;
