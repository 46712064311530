import {useEffect, useState} from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useAsync from "./useAsync";
import ReparationServices from "../services/ReparationServices";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectedCar} from "../utils";
import {notifyError} from "../utils/toast";
import {CLIENT} from "../pref";
import dayjs from "dayjs";

const useRepairNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedReparation = location?.state?.reparation;

  const user = useSelector((state) => state.authSlice.userInfo);

  useEffect(() => {
    if(selectedReparation === null || selectedReparation === undefined || user?.data?.roles[0]?.name !== CLIENT){
      navigate("/repair")
    }else{
      formik.setFieldValue("date", dayjs(new Date()).format("YYYY-MM-DD"))
      formik.setFieldValue("mileage", car?.mileage.toString())
    }
  }, [selectedReparation]);

  const [reparationComponentList, setReparationComponentList] = useState([]);

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const {
    loading: loadingComponent,
    data: reparationComponent,
    error: errorComponent
  } = useAsync(() => ReparationServices.getCarReparationComponents(selectedReparation?.id));

  useEffect(() => {
    if(reparationComponent?.data){
      const other1 = {
        "id": 0,
        "label": "Petites fournitures",
      }
      const other2 = {
        "id": -1,
        "label": "Autres pièces",
      }

      const temp = [other2, other1, ...reparationComponent?.data];
      setReparationComponentList(temp);
    }
  }, [reparationComponent]);

  // console.log("reparationComponent ", reparationComponent)

  const [loading, setLoading] = useState(false);
  const initialValues = {
    date: "",
    mileage: "",
    pieces: [
      {
        piece: "",
        cost: "",
        detail: "",
      }
    ],
    costOeuvre: "",
    reference: "",
    comment: "",
  };

  const validationSchema = yup.object().shape({
    date: yup.string().required("La date est obligatoire"),
    mileage: yup.number().required("Le kilométrage est obligatoire")
              .min(car?.mileage, `le kilométrage doit être supérieur ou égal à ${car?.mileage}`),
    pieces: yup.array()
      .of(yup.object().shape({
        piece: yup.string().required("Obligatoire"),
        cost: yup.string().required("Obligatoires"),
      })),
    costOeuvre: yup
      .number()
      .required("Le  coût de la main d'oeuvre est obligatoire"),
    reference: yup.string().required("La Reférence du produit est obligatoire"),
    // comment: yup.string().required("Le commentaire est obligatoire"),
  });

  const onSubmit = (e) => {
    setLoading(true);

    let component = [];

    e.pieces.forEach((item)=> {
      let piece = {
        "price": item.cost,
        "component_type": item.piece.toString() === "-1"  ? 'unregistered' : (item.piece.toString() === "0" ? 'little_furniture' : 'registered'),
      }

      if(item.piece.toString() !== "0" && item.piece.toString() !== "-1"){
        piece["id"] = item.piece
      }else{
        piece["details"] = item.detail
      }

      component.push(piece)
    })

    const body = {
      "current_mileage": e.mileage,
      "service_cost": e.costOeuvre,
      "date": e.date,
      "reference": e.reference,
      "vehicle_id": car?.id,
      "components": component,
      "system_id": selectedReparation?.id
    }

    ReparationServices.addCarReparation(body)
      .then((res)=> {
        console.log(res?.data)
        navigate(-1)
      })
      .catch((res) => notifyError(res?.response?.data?.message))
      .finally(()=> setLoading(false))
  }


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    reparationComponentList,
    selectedReparation,
    loadingComponent,
    errorComponent,
    loading
  }
}

export default useRepairNew;
