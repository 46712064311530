import ErrorForm from "../form/Error";
import React from "react";
import useRegister from "../../hooks/useRegister";

const LoginForm = () => {
  const { formik, loading } = useRegister();
  return (
      <form
          onSubmit={formik.handleSubmit}
          className="max-w-lg mt-12 px-6 w-full flex flex-col gap-8 items-center justify-center"
      >
        <div className="w-full">
          <input
              type="text"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={"firstName"}
              placeholder="prénom *"
              className="inputForm"
          />
          {formik.touched.firstName && formik.errors.firstName && (
              <ErrorForm message={formik.errors.firstName}/>
          )}
        </div>

        <div className="w-full">
          <input
              type="text"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={"lastName"}
              placeholder="Nom *"
              className="inputForm"
          />
          {formik.touched.lastName && formik.errors.lastName && (
              <ErrorForm message={formik.errors.lastName}/>
          )}
        </div>


        <div className="w-full">
          <div className="relative box-border ">
            <input
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={"phoneNumber"}
                type="number"
                disabled={true}
                className="inputFormPrefix ml-2"
                placeholder="Numéro de téléphone"
            />
            <div className="absolute z-0 bottom-[18px] text-white flex justify-center items-center">
              <span className="">+237</span>
            </div>
          </div>
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <ErrorForm message={formik.errors.phoneNumber}/>
          )}
        </div>

        <div className="w-full">
          <input
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={"email"}
              placeholder="Votre adresse email"
              className="inputForm"
          />
          {formik.touched.email && formik.errors.email && (
              <ErrorForm message={formik.errors.email}/>
          )}
        </div>

        <div className="w-full">
          <input
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={"password"}
              placeholder="Mot de passe *"
              className="inputForm"
          />
          {formik.touched.password && formik.errors.password && (
              <ErrorForm message={formik.errors.password}/>
          )}
        </div>

        <div className="w-full">
          <input
              type="password"
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={"passwordConfirmation"}
              placeholder="Confirmer le mot de passe *"
              className="inputForm"
          />
          {formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation && (
                  <ErrorForm message={formik.errors.passwordConfirmation}/>
              )}
        </div>

        <button
            disabled={loading}
            type="submit"
            className="buttonForm mt-16 focus:outline-none"
        >
          S'INSCRIRE
        </button>
      </form>
  );
};

export default LoginForm;
