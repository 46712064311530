import React from "react";
import {useNavigate} from "react-router-dom";
import CarNewForm from "../../components/auth/CarNewForm";
import Main from "../../layout/Main";

const CarNew = () => {
  const navigate = useNavigate();

  return (
    <Main selected="empty">
      <div className="flex-1 w-full h-full overflow-y-auto bg-primary flex justify-center border-t-2">
        <div className="max-w-[550px] w-full px-6 md:px-0 pb-4">
          <div className="mt-16 text-center">
            <div className="uppercase text-[#FFFFFF] font-bold">
              nouveau véhicule
            </div>
            <div className="text-white/40">Veuillez remplir ce formulaire</div>
          </div>
          <CarNewForm/>
          <button
            onClick={() => {
              navigate(`/`);
            }}
            className="inputSecondary"
            type="button">
            annuler
          </button>
        </div>
      </div>
    </Main>
  );
};

export default CarNew;
