import React, {useContext, Suspense, useEffect, lazy, useState} from "react";
import {useLocation, Outlet, useNavigate} from "react-router-dom";

import Sidebar from "../components/sidebar/Sidebar";
import { SidebarContext } from "../context/SidebarContext";
import ThemeSuspense from "../components/theme/ThemeSuspense";
import { useSelector, useDispatch } from 'react-redux';
import {fetchCars} from "../redux-toolkit/carManagementSlice";

const Layout = () => {
  const dispatch = useDispatch();

  const { isSidebarOpen, closeSidebar, navBar } = useContext(SidebarContext);
  let location = useLocation();

  const user = useSelector((state) => state.authSlice.userInfo);

  const loadCars = () => dispatch(fetchCars(user?.data?.uuid));

  useEffect(() => {
    closeSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    loadCars()
  }, []);

  return (
      <div className="max-h-screen w-full flex justify-center bg-gray-100 relative">
          <div
              className={`max-w-6xl w-full flex bg-gray-100 z-50 ${
                  isSidebarOpen && "overflow-hidden"
              }`}
          >
              {navBar && <Sidebar/>}

              <div
                  className="flex flex-col justify-center items-center flex-1 min-h-screen w-full overflow-hidden bg-white">
                  <div className="flex flex-col h-full w-full max-w-4xl border-r border-l">
                      <Suspense fallback={<ThemeSuspense/>}>
                          <Outlet/>
                      </Suspense>
                  </div>
              </div>
          </div>
          <div className="bg-primary/75 w-full h-56 absolute "/>
      </div>
  );
};

export default Layout;
