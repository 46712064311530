import ErrorForm from "../form/Error";
import React from "react";
import useLogin from "../../hooks/useLogin";

const LoginForm = () => {
  const { formik, loading } = useLogin();

  return (
      <form
          onSubmit={formik.handleSubmit}
          className="max-w-lg  px-6 w-full flex flex-col items-center justify-center"
      >
        <div className="w-full mt-8">
          <input
              type="text"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={"phone"}
              placeholder="Numéro de téléphone ou adresse email"
              className="inputForm"
          />
          {formik.touched.phone && formik.errors.phone && (
              <ErrorForm message={formik.errors.phone}/>
          )}
        </div>

        <div className="w-full mt-6">
          <input
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={"password"}
              placeholder="Mot de passe"
              className="inputForm"
          />
          {formik.touched.password && formik.errors.password && (
              <ErrorForm message={formik.errors.password}/>
          )}
        </div>

        <button disabled={loading} type="submit" className={`buttonForm uppercase mt-16 ${loading ? "bg-gray-400 hover:bg-gray-400" : ""}`}>
          {loading ? "chargement..." : "CONNEXION"}
        </button>
      </form>
  );
};

export default LoginForm;
