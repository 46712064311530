import { useState} from "react";
import { useNavigate } from "react-router-dom";
import menu from "../../images/menu.png";
import alrightTick from "../../images/alright.png";
import {BsFilterLeft} from "react-icons/bs";
import {MdOutlineCheck} from "react-icons/md";

function BoxUtilisation({
    isWrap,
    filterTitle,
    filterValue,
    handleWrap,
    MouseOver,
    MouseOut,
    period,
    handlePeriod,
    handleOpenDialog,
}) {

    return (
        <div
            onMouseOver={MouseOver}
            onMouseOut={MouseOut}
            className="flex flex-col items-center">

            <button className='text-white text-2xl p-2 focus:outline-none'>
                <BsFilterLeft className=''/>
            </button>

            {isWrap && (
                <div className='absolute text-xs bg-white top-12 right-3 flex-col items-start border-hoverBleu-100 shadow-lg -mt-3'>
                    {filterTitle?.map((item, index)=> {
                        return (
                            <button key={index} onClick={()=>{
                                if(index === (filterValue.length - 1)){
                                    handleOpenDialog(filterValue[index])
                                }else{
                                    handlePeriod(filterValue[index])
                                }
                            }}
                                className="w-full flex justify-start items-center gap-3 px-8 py-3 focus:outline-none text-gray-500 font-semibold hover:bg-gray-200">
                                <p> {item}</p>
                                <p>
                                    {(filterValue[index] === period) && (
                                        <MdOutlineCheck className="text-lg text-primary"/>
                                    )}
                                </p>
                            </button>
                        )
                    })}
                </div>
            )}

        </div>
    )
}

export default BoxUtilisation;