import {createAsyncThunk, createNextState, createSlice} from '@reduxjs/toolkit';
import CarServices from "../services/CarServices";
import Cookies from "js-cookie";
import {SELECTED_CAR_INDEX, USER_INFO} from "../pref";
import {selectedCar} from "../utils";

export const fetchCars = createAsyncThunk('data/fetchData', async (id) => {
    const response = await CarServices.getCar(id)
    return response.data;
});

const CarManagementSlice = createSlice({
    name: 'CarManagement',
    initialState: {
        loading: true,
        error: "",
        data: null
    },
    reducers: {
        loadAllCars: fetchCars,
        unSelectCar: (state, action) => {
            state.data.map(item => {
                const itemTemp = item
                itemTemp.selected = false
                return itemTemp
            })
        },
        removeCar: (state, action) => {
            state.data = state.data.filter(item => item.car?.id !== action.payload)
        },
        setCarAction: (state, action) => {
            state.data.map(item => {
                const itemTemp = item
                itemTemp.selected = false
                return itemTemp
            })
            state.data[action.payload].selected = true
            localStorage.setItem(SELECTED_CAR_INDEX, action.payload);
            // console.log("state ", state.data[action.payload].selected)
            // console.log("payload ", action.payload)
        },
        addNewCar: (state, action) => {
            state.data.push({
                selected: false,
                car: action.payload
            })
        },
        updateMileage: (state, action) => {
            const selectedCarIndex = localStorage.getItem(SELECTED_CAR_INDEX);
            state.data[selectedCarIndex].car = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCars.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCars.fulfilled, (state, action) => {
                const carFormat = []

                action.payload?.data.forEach(car => {
                    carFormat.push({
                        selected: false,
                        car: car
                    })
                })

                const selectedCarIndex = localStorage.getItem(SELECTED_CAR_INDEX);

                // console.log("selectedCarIndex ", selectedCarIndex)
                // selectedCarIndex.toString() !== "undefined"
                if(selectedCarIndex.toString() !== "null"){
                    carFormat[selectedCarIndex].selected = true
                }

                // console.log("carFormat ", carFormat)

                state.data = carFormat;
                state.error = ""
                state.loading = false;
            })
            .addCase(fetchCars.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            });
    },
});
export const { setCarAction, addNewCar, removeCar, unSelectCar, updateMileage } = CarManagementSlice.actions
export default CarManagementSlice.reducer;