import {useEffect, useState} from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useAsync from "./useAsync";
import ReparationServices from "../services/ReparationServices";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectedCar} from "../utils";
import {notifyError} from "../utils/toast";
import {CLIENT} from "../pref";
import BreakDownServices from "../services/BreakdownServices";
import dayjs from "dayjs";

const useBreakdownRepair = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedBreakdown = location?.state?.breakdown;

  const user = useSelector((state) => state.authSlice.userInfo);

  useEffect(() => {
    console.log("selectedBreakdown ", selectedBreakdown)
    if(selectedBreakdown === null || selectedBreakdown === undefined || user?.data?.roles[0]?.name !== CLIENT){
      navigate("/")
    }else{
      formik.setFieldValue("date", dayjs(new Date()).format("YYYY-MM-DD"))
      formik.setFieldValue("mileage", car?.mileage.toString())
    }
  }, [selectedBreakdown]);

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const [loading, setLoading] = useState(false);
  const initialValues = {
    date: "",
    mileage: "",
    cost: "",
    costOeuvre: "",
    reference: "",
    comment: "",
  };

  const validationSchema = yup.object().shape({
    date: yup.string().required("La date est obligatoire"),
    mileage: yup.number().required("Le kilométrage est obligatoire")
              .min(car?.mileage, `le kilométrage doit être supérieur ou égal à ${car?.mileage}`),
    cost: yup.number().required("Le coût des pièces sont obligatoires"),
    costOeuvre: yup
      .number()
      .required("Le  coût de la main d'oeuvre est obligatoire"),
    reference: yup.string().required("La Reférence du produit est obligatoire"),
    // comment: yup.string().required("Le commentaire est obligatoire"),
  });

  const onSubmit = (e) => {
    setLoading(true);

    const body = {
      "completed": true,
      "price": e.cost,
      "current_mileage": e.mileage,
      "service_cost": e.costOeuvre,
      "date": e.date,
      "reference": e.reference,
      "vehicle_id": car?.id,
      "system_component_id": selectedBreakdown?.system_component?.id
  }

    BreakDownServices.updateCarBreakDown(body, selectedBreakdown?.id)
      .then((res)=> {
        // console.log(res?.data)
        navigate(-1)
      })
      .catch((res) => notifyError(res?.response?.data?.message))
      .finally(()=> setLoading(false))
  }


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    selectedBreakdown,
    loading
  }
}

export default useBreakdownRepair;
