import React from "react";
import { useTranslation } from "react-i18next";
import SignupForm from "../../components/auth/SignupForm";
import { useNavigate } from "react-router-dom";
import {REGISTER} from "../../pref";

const Signup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-full font-myfont min-h-screen py-1 flex-col bg-primary flex items-center">
      <div className="max-w-[1500px] w-full flex flex-col items-center  mt-10">
        <div className="max-w-[1500px] flex flex-col items-center ">
          <div className="text-md mx-6 text-white font-bold uppercase">
            INSCRIPTION
          </div>
          <p className=" text-sm mt-1 text-white/70 font-semibold text-center">
            creer un compte gveo
          </p>
        </div>

        <SignupForm/>

        <div className="max-w-lg px-6 w-full flex  flex-col my-2 justify-center items-center">
          <button
              className="outlinedButton capitalize"
              onClick={() => {
                navigate("/login");
              }}
          >
            annuler
          </button>
        </div>
      </div>
    </div>
  );
};

export default Signup;
