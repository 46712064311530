import ErrorForm from "../form/Error";
import React from "react";
import { useNavigate } from "react-router-dom";
import useOperationNew from "../../hooks/useOperationNew";
import InputFormik from "../form/InputFormik";
import useDocumentForm from "../../hooks/useDocumentForm";
import dayjs from "dayjs";

const FormDocumentNew = () => {
  const { formik, loading, user } = useDocumentForm();

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="relative flex flex-col gap-8">
        <div className="w-full">
          <InputFormik
            label="Date de souscription"
            placeholder=""
            name="dateSub"
            value={formik.values.dateSub}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="date"
            shrink={true}
            min={dayjs(Date.now()).format("YYYY-MM-DD")}
            max={formik.values?.dateExpire?.length === 0 ? new Date() : formik.values.dateExpire}
          />
          {formik.touched.dateSub && formik.errors.dateSub && (
            <ErrorForm message={formik.errors.dateSub}/>
          )}
        </div>

        <div className="w-full">
          <InputFormik
            label="Date d'expiration"
            placeholder=""
            name="dateExpire"
            value={formik.values.dateExpire}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="date"
            shrink={true}
            min={dayjs(Date.now()).format("YYYY-MM-DD")}
          />
          {formik.touched.dateExpire && formik.errors.dateExpire && (
            <ErrorForm message={formik.errors.dateExpire}/>
          )}
        </div>

        <div className="w-full">
          <InputFormik
            label="Cout"
            placeholder=""
            name="cost"
            value={formik.values.cost}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
          />
          {formik.touched.cost && formik.errors.cost && (
            <ErrorForm message={formik.errors.cost}/>
          )}
        </div>

        <div
            className={["w-full active:bg-secondary p-2 text-center rounded my-5 text-white", loading ? "bg-gray-400" : "hover:bg-[#2dcf34] bg-secondary"].join(" ")}>
          <button
            className="focus:outline-none uppercase w-full"
            type="submit"
            disabled={loading}
          >
            {loading ? "chargement..." : "enregistrer"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormDocumentNew;
