import React from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {CLIENT} from "../pref";
import {BottomNavigation} from "@mui/material";

const Nav = ({ children, selected }) => {

    // console.log('selected ', selected)

    const navigate = useNavigate();
    const user = useSelector((state) => state.authSlice.userInfo);

    // console.log("user ", user)

    const navigations = [
        {
            "icon": "fa-solid fa-screwdriver-wrench",
            "title": "Entretien",
            "route": "/",
        },
        {
            "icon": "fa-solid fa-car-burst",
            "title": "Reparation",
            "route": "/repair",
        },
        {
            "icon": "fa-solid fa-toolbox",
            "title": "Legislation",
            "route": "/legislation",
        }
    ]

    if(user?.data?.roles[0]?.name === CLIENT){
        navigations?.push({
            "icon": "fa-solid fa-signal",
            "title": "Utilisation",
            "route": "/utilisation",
        })
    }

  return (
      // <BottomNavigation
      //     showLabels
      //     value={selected}
      //     onChange={(event, newValue) => {
      //         navigate(`${nav.route}`)
      //         // setValue(newValue);
      //     }}
      // >
      //     {navigations.map((nav, i) => {
      //         return (
      //             <button
      //                 key={i}
      //                 type="button"
      //                 onClick={() => navigate(`${nav.route}`)}
      //                 className={["inline-flex flex-col items-center justify-center py-2 hover:text-blue-600 focus:outline-none", selected == navigations[i].title ? "text-blue-500" : "text-gray-500"].join(" ")}
      //             >
      //                 <i className={`text-xl ${nav.icon}`}></i>
      //                 <span className="text-xs md:text-sm">
      //                   {nav.title}
      //                 </span>
      //             </button>
      //         )
      //     })}
      // </BottomNavigation>
      <div className="flex-none w-full flex justify-evenly flex-wrap border-t">
          {navigations.map((nav, i) => {
              return (
                  <button
                      key={i}
                      type="button"
                      onClick={() => navigate(`${nav.route}`)}
                      className={["inline-flex flex-col items-center justify-center py-2 hover:text-blue-600 focus:outline-none", selected == navigations[i].title ? "text-blue-500" : "text-gray-500"].join(" ")}
                  >
                      <i className={`text-xl ${nav.icon}`}></i>
                      <span className="text-xs md:text-sm">
                        {nav.title}
                      </span>
                  </button>
              )
          })}
      </div>
  );
};

export default Nav;
