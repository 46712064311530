import requests from "./httpService";

const DocumentService = {
  getCarDocument: async (id) => {
    return requests.get(`/document-types/vehicle/${id}`);
  },

  addCarDocument: async (body) => {
    return requests.post(`/legal-documents`, body);
  },
};
export default DocumentService;
