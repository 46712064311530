import React from "react";

const ErrorForm = ({ message }) => {
  return (
      <>
        <div className="ml-1 mt-1 text-sm text-red-600">{message}</div>
      </>
  );
};

export default ErrorForm;
