// import React from "react";
//
// const InputFormik = (props) => {
//   return (
//       <div className="w-full">
//           <input
//               {...props}
//               className="input w-full placeholder-gray-500 placeholder-opacity-0 border-b-2 border-white text-white bg-transparent focus:outline-none"
//           />
//           <label
//               className="label absolute left-0 text-white origin-[0] transition duration-500 ease-in-out"
//               htmlFor={props.label}
//           >
//               {props.label}
//           </label>
//       </div>
//   );
// };
//
// export default InputFormik;

import React from 'react';
import { TextField } from "@mui/material";

const InputFormik = ({
  formik,
  ...props
}) => {
    return (
        <TextField
            fullWidth
            variant="standard"
            InputProps={{
                style: {
                    color: props.inputColor ?? "white"
                }
            }}
            InputLabelProps={{
                shrink: props.shrink,
                style: { color: props.inputLabel ?? "#fffefe", opacity: 0.5 },
            }}
            {...props}
        />
    );
};

export default InputFormik;
