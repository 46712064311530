// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import CarManagementSlice from "./carManagementSlice";
import SideBarMenuSlice from "./sideBarMenuSlice";
import AuthSlice from "./authSlice";

const store = configureStore({
  reducer: {
    carManagementSlice: CarManagementSlice,
    sideBarMenuSlice: SideBarMenuSlice,
    authSlice: AuthSlice
  }
});

export default store;