import {useEffect, useState} from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {useSelector} from "react-redux";
import {selectedCar} from "../utils";
import {useLocation, useNavigate} from "react-router-dom";
import {notifyError} from "../utils/toast";
import dayjs from "dayjs";
import {CLIENT} from "../pref";
import useAsync from "./useAsync";
import CarServices from "../services/CarServices";
import BreakdownServices from "../services/BreakdownServices";

const useBreakdownNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedOperation = location?.state?.operation;

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const user = useSelector((state) => state.authSlice.userInfo);

  useEffect(() => {
    if(car === null || user?.data?.roles[0]?.name !== CLIENT){
      navigate("/")
    }
  }, []);

  const {
    loading: loadingComponent,
    data: componentData,
    error: errorComponent
  } = useAsync(() => CarServices.getAllCarSystem());

  const [loadingSystemComponent, setLoadingSystemComponent] = useState(false);
  const [systemComponentData, setSystemComponentData] = useState([]);
  function getSystemComponent(id) {
    setLoadingSystemComponent(true)

    CarServices.getAllCarSystemComponents(formik.values.component)
        .then(res => setSystemComponentData(res?.data?.data))
        .catch(err => notifyError(err?.response?.data?.message))
        .finally(()=> setLoadingSystemComponent(false))
  }

  const [loading, setLoading] = useState(false);
  const initialValues = {
    component: "",
    systemComponent: ""
  };

  const validationSchema = yup.object().shape({
    component: yup.string().required("Veuillez sélectionner le composant"),
    systemComponent: yup.string().required("Veuillez sélectionner la pièce")
  });

  const onSubmit = (e) => {
    setLoading(true);

    const body = {
      "vehicle_id": car?.id,
      "system_component_id": e.systemComponent,
      "date": dayjs(Date.now()).format("YYYY-MM-DD")
    }

    BreakdownServices.addBreakDown(body)
      .then((res)=> {
        navigate(-1)
      })
      .catch((res) => notifyError(res?.response?.data?.message))
      .finally(()=> setLoading(false))
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if(formik.values.component !== ""){
      formik.setFieldValue('systemComponent', "")
      setSystemComponentData([])
      getSystemComponent()
    }
  }, [formik.values.component]);

  return {
    formik,
    componentData,
    loadingSystemComponent,
    systemComponentData,
    loading,
  };
};

export default useBreakdownNew;
