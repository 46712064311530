import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const UtilisationSortDialog = ({
    closeModal,
    handleCloseDialog,
    from,
    setFrom,
    to,
    setTo,
    onSubmit
}) => {
    const { t } = useTranslation();

    return (
        <form
            className="w-full  flex-col text-gray-500"
            onSubmit={onSubmit}
        >
            <div className="py-7 px-5">
                <label className="flex items-center gap-2 text-base pb-1">
                    <p className="font-semibold">De</p>
                    <input
                        required
                        className="w-full shadow rounded-sm py-3 border px-3"
                        type="date"
                        value={from}
                        max={dayjs(Date.now()).format("YYYY-MM-DD")}
                        onChange={(event) => setFrom(event.target.value)}
                    />
                </label>
                <p>|</p>
                <label className="flex items-center gap-4 text-base pb-1">
                    <p className="font-semibold">A</p>
                    <input
                        required
                        className="w-full shadow rounded-sm py-3 border px-3"
                        type="date"
                        value={to}
                        max={dayjs(Date.now()).format("YYYY-MM-DD")}
                        onChange={(event) => setTo(event.target.value)}
                    />
                </label>
            </div>

            <div className="w-full flex justify-between">
                <button
                    type="submit"
                    className="flex-1 md:p-3 p-2 bg-primary text-white"
                >
                    Valider
                </button>
                <button
                    type="button"
                    className="flex-1 md:p-3 p-2 bg-red-500 text-white"
                    onClick={handleCloseDialog}
                >
                    Annuler
                </button>
            </div>
        </form>
    );
};

export default UtilisationSortDialog;
