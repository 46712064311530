// import InputArea from "../form/InputArea";
// import useLoginSubmit from "../../hooks/useLoginSubmit";
// import * as yup from "yup";
// import { useFormik } from "formik";
// import ErrorForm from "../form/Error";
// import useLogin from "../../hooks/useLogin";
import { FORGOT_PASSWORD, REGISTER } from "../../pref";
import LoginForm from "../../components/auth/LoginForm";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormeForgotPassword from "../../components/auth/FormeForgotPassword";
const ForgotPassword = () => {
  return (
    <div className="w-full font-myfont min-h-screen py-1  flex-col bg-primary flex items-center">
      <div className="max-w-[1500px] w-full flex flex-col justify-center items-center mt-10">
        <div className="max-w-[1500px] flex flex-col items-center ">
          <div className="text-md  mx-6 text-white font-bold">
            MOT DE PASSE OUBLIÉ
          </div>
          <p className="text-sm mt-1 text-white opacity-60 font-bold">
            Veiller renseigner vos informations confidentiel{" "}
          </p>
        </div>
        <FormeForgotPassword />
      </div>
    </div>
  );
};

export default ForgotPassword;
