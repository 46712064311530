import React from "react";
import ErrorForm from "../form/Error";
import useRepairNew from "../../hooks/useRepairNew";
import InputFormik from "../form/InputFormik";
import TextAreaFormik from "../form/textareaFormik";
import useBreakdownRepair from "../../hooks/useBreakdownRepair";
const FormRepairNew = () => {
  const {
    formik,
    selectedBreakdown,
    loading
  } = useBreakdownRepair();

  return (
      <form onSubmit={formik.handleSubmit} className="relative flex flex-col gap-8">
          <div className="w-full">
              <InputFormik
                  label="Date de la réparation"
                  placeholder=""
                  name="date"
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="date"
                  shrink={true}
              />
              {formik.touched.date && formik.errors.date && (
                  <ErrorForm message={formik.errors.date}/>
              )}
          </div>

          <div className="w-full">
              <InputFormik
                  label="Kilométrage a la date"
                  placeholder=""
                  name="mileage"
                  value={formik.values.mileage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
              />
              {formik.touched.mileage && formik.errors.mileage && (
                  <ErrorForm message={formik.errors.mileage}/>
              )}
          </div>

          <div className="w-full">
              <InputFormik
                  label="Pièces"
                  placeholder=""
                  disabled={true}
                  value={selectedBreakdown?.system_component?.label}
                  type="text"
              />
          </div>

          <div className="w-full">
              <InputFormik
                  label="Coût de la pièces"
                  placeholder=""
                  name="cost"
                  value={formik.values.cost}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
              />
              {formik.touched.cost && formik.errors.cost && (
                  <ErrorForm message={formik.errors.cost}/>
              )}
          </div>

          <div className="w-full">
              <InputFormik
                  label="Coût de la main d'oeuvre"
                  placeholder=""
                  name="costOeuvre"
                  value={formik.values.costOeuvre}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
              />
              {formik.touched.costOeuvre && formik.errors.costOeuvre && (
                  <ErrorForm message={formik.errors.costOeuvre}/>
              )}
          </div>

          <div className="w-full">
              <InputFormik
                  label="Reférence du produit"
                  placeholder=""
                  name="reference"
                  value={formik.values.reference}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
              />
              {formik.touched.reference && formik.errors.reference && (
                  <ErrorForm message={formik.errors.reference}/>
              )}
          </div>

          <div className="w-full">
              <TextAreaFormik
                  label="Faite un commentaire"
                  placeholder=""
                  name="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
              />
              {formik.touched.comment && formik.errors.comment && (
                  <ErrorForm message={formik.errors.comment}/>
              )}
          </div>

          <div
              className={["w-full active:bg-secondary p-2 text-center rounded mb-5 text-white", loading ? "bg-gray-400" : "hover:bg-[#2dcf34] bg-secondary"].join(" ")}>
              <button
                  className="focus:outline-none uppercase w-full"
                  type="submit"
                  disabled={loading}>
                  {loading ? "chargement..." : "enregistrer"}
              </button>
          </div>
      </form>
  );
};

export default FormRepairNew;
