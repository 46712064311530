import requests from "./httpService";

const ReparationServices = {
  getCarReparation: async () => {
    return requests.get(`/vehicle_systems`);
  },

  getCarReparationList: async (reparationId, carId) => {
    return requests.get(`/repairs/vehicle/system/${reparationId}/${carId}`);
  },

  getCarReparationComponents: async (reparationId) => {
    return requests.get(`/system_components/system/${reparationId}`);
  },

  addCarReparation: async (body) => {
    return requests.post(`/repairs`, body);
  },
};
export default ReparationServices;
