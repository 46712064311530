import ErrorForm from "../form/Error";
import React from "react";
import InputFormik from "../form/InputFormik";
import useEmployeeForm from "../../hooks/useEmployeeForm";

const FormEmployeeNew = () => {
  const {
    formik,
    loading,
    showPassword,
    handlePassword,
    roleData
  } = useEmployeeForm();

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="relative flex flex-col gap-8">
        <div className="w-full">
          <InputFormik
              label="Prénom de l'employée"
              placeholder=""
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
          />
          {formik.touched.firstName && formik.errors.firstName && (
              <ErrorForm message={formik.errors.firstName}/>
          )}
        </div>

        <div className="w-full">
          <InputFormik
              label="Nom de l'employée"
              placeholder=""
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
          />
          {formik.touched.lastName && formik.errors.lastName && (
              <ErrorForm message={formik.errors.lastName}/>
          )}
        </div>

        <div className="w-full">
          <InputFormik
              label="Numéro de téléphone"
              placeholder=""
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="number"
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <ErrorForm message={formik.errors.phoneNumber}/>
          )}
        </div>

        {roleData?.data?.length !== 0 && (
            <div>
              <div className="flex-col w-full flex text-white border-b-2 border-solid border-white">
                <label
                    className="text-white relatve text-base  pointer-events-none"
                    htmlFor="type-v"
                >
                  Role de l'employé
                </label>
                <select
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full focus:outline-none  bg-transparent"
                    name="role">
                  <option
                      hidden={true}
                      className="text-black"
                      value="0">
                    Sélectionnez un role
                  </option>
                  {roleData?.data?.map((item, index) => {
                    return (
                        <option
                            key={index}
                            className="text-black"
                            value={item?.id}>
                          {item?.name}
                        </option>
                    )
                  })}
                </select>
              </div>
              {formik.touched.role && formik.errors.role && (
                  <ErrorForm message={formik.errors.role}/>
              )}
            </div>
        )}

        <div className="w-full">
          <div className="relative">
            <InputFormik
                label="Mot de passe"
                placeholder=""
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type={showPassword ? "text" : "password"}
            />
            <button
                onClick={handlePassword}
                type="button"
                className="uppercase text-white text-sm absolute right-0 bottom-2 hover:bg-black/20 py-1 px-2">
              voir
            </button>
          </div>
          {formik.touched.password && formik.errors.password && (
              <ErrorForm message={formik.errors.password}/>
          )}
        </div>

        <button
            disabled={loading}
            type="submit"
            className={`buttonForm uppercase my-5 ${loading ? "bg-gray-400 hover:bg-gray-400" : ""}`}>
          {loading ? "chargement..." : "CONNEXION"}
        </button>
      </form>
    </div>
  );
};

export default FormEmployeeNew;
