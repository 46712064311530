import React, {useEffect, useState} from "react";
import backArrow from "../../images/backArrow.png";
import horizontalLines from "../../images/horizontalLines.png";
import {useLocation, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import profile from '../../images/profil.png';
import {useSelector} from "react-redux";
import {selectedCar} from "../../utils";
import MaintenanceServices from "../../services/MaintenanceServices";
import {LiaArrowLeftSolid} from "react-icons/lia";
import Skeleton from "react-loading-skeleton";
import dayjs from "dayjs";
import {HiBars3} from "react-icons/hi2";
import AuthServices from "../../services/AuthServices";
import {FaUser} from "react-icons/fa";

const Employee = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const user = useSelector((state) => state.authSlice.userInfo);
    const cars = useSelector((state) => state.carManagementSlice.data);
    const car = selectedCar(cars)

    const [usersListData, setUsersListData] = useState([] || {});
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadUsersList()
    }, []);

    function loadUsersList () {
        setError("")
        setLoading(true)
        AuthServices
            .getAllDriver(user?.data?.uuid)
            .then(res => setUsersListData(res?.data))
            .catch(err => setError(err.message))
            .finally(()=> setLoading(false))
    }

    // console.log("usersListData ", usersListData?.data)

    return (
        <>
            <div className="flex  items-center py-3   bg-primary">
                <div
                    onClick={() => navigate(-1)}
                    className="pl-4 pr-8 cursor-pointer"
                >
                    <LiaArrowLeftSolid className="text-white text-2xl"/>
                </div>
                <div className="font-myfont text-base md:text-lg text-white">
                    Listes des employés
                </div>
            </div>

            {loading && (
                <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-6 px-6 my-4">
                    {[1, 2].map(item => {
                        return (
                            <div key={item}>
                                <Skeleton
                                    count={4}
                                    height={140}
                                    className="bg-gray-600"
                                />
                            </div>
                        )
                    })}
                </div>
            )}

            {(!loading && error.length !== 0) && (
                <div className="flex-1 h-full flex flex-col justify-center items-center text-red-500 gap-4 my-4">
                    <div className="text-center">Une erreur est survenu veuillez réessayer</div>
                    <button
                        onClick={loadUsersList}
                        className="uppercase px-8 py-1.5 rounded hover:bg-red-500/10 active:bg-red-500/20 focus:outline-none border-2 border-red-500">
                        réessayer
                    </button>
                </div>
            )}

            {(!loading && error.length === 0 && usersListData?.data?.length !== 0) && (
                <div className="bg-white flex-1 flex flex-col justify-start items-center overflow-y-auto">
                    <div className="w-full flex flex-col px-3 mt-2 mb-4 justify-center items-center">
                        <div className="mt-4 w-full grid grid-cols-1 lg:grid-cols-2 gap-3">
                            {usersListData?.data?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="w-full flex items-center gap-4 rounded-lg bg-white lg:max-w-[450px] flex-wrap shadow border p-4">
                                        <FaUser className="text-3xl text-primary"/>
                                        <div>
                                            <p className="font-semibold">{`${item.first_name} ${item.last_name}`}</p>
                                            <p className="text-gray-500 text-sm">{item?.roles[0]?.name}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
            )}

            {(!loading && error.length === 0 && usersListData?.data?.length === 0) && (
                <div className="bg-white flex-1 flex flex-col justify-center items-center overflow-y-auto">
                    <div
                        className="w-full flex flex-col gap-2 px-3 mt-2 mb-4 text-center text-gray-500 justify-center items-center">
                        <HiBars3 className="text-[150px] text-gray-300"/>
                        <div className="max-w-md font-semibold">
                            Vous n'avez aucune opération enregistrée
                            {/*pour transmission et pneumatique*/}
                        </div>
                    </div>
                </div>
            )}

            <button
                className="flex justify-center items-center py-3 bg-primary text-white hover:bg-hoverBleu-200 active:bg-primary"
                onClick={() => navigate("/addEmployee", {
                    replace: false,
                    // state: {
                    //     operation: selectedOperation,
                    // },
                })}
            >
                Enregistrer un employée
            </button>
        </>
    )
}

export default Employee;