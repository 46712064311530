import React from "react";
import FormRepairNew from "../../components/auth/FormRepairNew";
import {useNavigate} from "react-router-dom";
import FormOperation from "../../components/auth/FormOperationNew";
import Main from "../../layout/Main";
import FormBreakRepair from "../../components/auth/FormBreakRepair";

const BreakdownRepair = () => {
  const navigate = useNavigate();

  return (
      <Main selected="empty">
        <div className="flex-1 w-full h-full overflow-y-auto bg-primary flex justify-center border-t-2">
          <div className="max-w-[550px] w-full px-6 md:px-0 pb-4">
            <div className="mt-16 text-center">
              <div className="uppercase text-[#FFFFFF] font-bold">
                enregistrer une réparation
              </div>
            </div>
            <div className="mt-16">
              <FormBreakRepair/>
            </div>
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="inputSecondary"
              type="button">
              annuler
            </button>
          </div>
        </div>
      </Main>
  );
};

export default BreakdownRepair;
