import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./assets/css/custom.css";
import "./assets/css/tailwind.css";
import 'react-loading-skeleton/dist/skeleton.css'
import App from './App';
import {SidebarProvider} from "./context/SidebarContext";
import { Provider } from "react-redux";
import store from "./redux-toolkit/Store";
import ThemeSuspense from "./components/theme/ThemeSuspense";
import {ThemeProvider} from "@mui/material";
import theme from "./theme";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <SidebarProvider>
            <Provider store={store}>
                <Suspense fallback={<ThemeSuspense />}>
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>
                </Suspense>
            </Provider>
        </SidebarProvider>
    </ThemeProvider>
);
