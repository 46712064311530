import {useContext, useEffect, useState} from "react";
import UtilisationServices from "../services/UtilisationServices";
import {useSelector} from "react-redux";
import {selectedCar} from "../utils";

const useUtilisation = (isFloat) => {
  const user = useSelector((state) => state.authSlice.userInfo);

  const filterTitle = [
    "Pour ce mois",
    "Pour le mois dernier",
    "Pour les 6 derniers mois",
    "Pour les 12 derniers mois",
    "Pour cette année",
    "Pour l'an dernier",
    "Pour les 3 dernières années",
    "Personnalisé"
  ];

  const filterValue = [
    "this_month",
    "last_3_months",
    "last_6_months",
    "last_12_months",
    "this_year",
    "last_year",
    "last_3_years",
    "custom"
  ];

  const chartTitles = [
    "Maintetenance préventive",
    "Maintenance curative",
    "Dépenses Administratives",
    "Consommation du carburant",
  ]

  const data = {
    labels: chartTitles,
    datasets: [
      {
        id: 1,
        label: "",
        data: [0,0,0,0]
      },
    ],
  };

  const [chartData, setChartData] = useState(data);

  const [period, setPeriod] = useState(filterValue[0]);
  const handlePeriod = (value)=> {
    handleWrap()
    setPeriod(value)
    loadPeriod(value)
  }

  const [isWrap, setIsWrap] = useState(false);
  const handleWrap = ()=> setIsWrap(old=> !old)

  const [isOpen, setIsOpen] = useState(false);

  const [periodTemp, setPeriodTemp] = useState(false);
  const handleOpenDialog = (value) => {
    setPeriodTemp(value)
    setIsOpen(true)
  }
  const handleCloseDialog = () => setIsOpen(false)

  const MouseOver = (event)=> handleWrap()
  const MouseOut = (event)=> handleWrap()

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const [statsData, setStatsData] = useState([] || {});
  const [error, setError] = useState("");
  const [loadingStats, setLoadingStats] = useState(false);

  const [costData, setCostData] = useState([] || {});
  const [errorCost, setErrorCost] = useState("");
  const [loadingCost, setLoadingCost] = useState(false);

  useEffect(() => {
    // if(car !== null){
    //   loadPeriod(period)
    // }

    loadCost()
    loadPeriod(period)
  }, [car]);

  function loadPeriod(period, from, to){
    setError("")
    setLoadingStats(true)
    UtilisationServices
      .getCarUtilisation(isFloat, car?.id, user?.data?.uuid, period, from, to)
      .then(res => {
        if(res?.data?.data){
          const data = {
            labels: chartTitles,
            datasets: [
              {
                id: 1,
                label: "",
                data: [
                  res?.data?.data?.preventive_perc,
                  res?.data?.data?.currative_perc,
                  res?.data?.data?.document_perc,
                  res?.data?.data?.fuel_perc
                ],
              },
            ],
          };

          setChartData(data)
        }

        setStatsData(res?.data)
      })
      .catch(err => setError(err.message))
      .finally(()=> setLoadingStats(false))
  }

  function loadCost(){
    setErrorCost("")
    setLoadingCost(true)
    UtilisationServices
      .getCarUtilisationCost(car?.id)
      .then(res => {
        // console.log(res?.data?.data?.fuel_consumption.months)
        setCostData(res?.data?.data)
      })
      .catch(err => setErrorCost(err.message))
      .finally(()=> setLoadingCost(false))
  }

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  function onSubmit(event) {
    event.preventDefault();
    handleCloseDialog()
    setPeriod(periodTemp)
    loadPeriod(periodTemp, from, to)
  }

  return {
    period,
    filterTitle,
    filterValue,
    isWrap,
    isOpen,
    handleWrap,
    MouseOver,
    MouseOut,
    statsData,
    loadingStats,
    error,
    car,
    handlePeriod,
    chartData,
    handleOpenDialog,
    handleCloseDialog,
    from,
    setFrom,
    to,
    setTo,
    onSubmit,
    costData,
    loadingCost,
    errorCost,
  };
};

export default useUtilisation;
