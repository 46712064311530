import requests from "./httpService";

const DepartureArrivalServices = {
  addCarCheckOut: async (body) => {
    return requests.post(`/vehicle-mvts`, body);
  },

  addCarCheckIn: async (body, id) => {
    return requests.put(`/vehicle-mvts/${id}`, body);
  }
};

export default DepartureArrivalServices;
