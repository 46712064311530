import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#fff',
        },
        secondary: {
            main: '#f50057',
        },
    },
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    ":focus": {
                        border: '3px solid #fff',
                    },
                },

            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '--TextField-brandBorderColor': '#E0E3E7',
                    '--TextField-brandBorderHoverColor': '#fffefe',
                    '--TextField-brandBorderFocusedColor': '#fff',
                    '& label.Mui-focused': {
                        color: '#fffefe',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: 'var(--TextField-brandBorderColor)',
                },
                // root: {
                //     [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                //         borderColor: 'var(--TextField-brandBorderHoverColor)',
                //     },
                //     [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                //         borderColor: 'var(--TextField-brandBorderFocusedColor)',
                //     },
                // },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    '&::before, &::after': {
                        borderBottom: '2px solid var(--TextField-brandBorderColor)',
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                    },
                    '&.Mui-focused:after': {
                        borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&::before': {
                        borderBottom: '2px solid var(--TextField-brandBorderColor)',
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                    },
                    '&.Mui-focused:after': {
                        borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                    },
                },
            },
        },
    },
});

export default theme;