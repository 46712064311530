import requests from "./httpService";

const FuelServices = {
  addFuel: async (body) => {
    return requests.post(`/fuel-logs`, body);
  },

  getAllFuel: async (car) => {
    let url = "fuel-logs";

    if(car !== null){
      url = `${url}/vehicle/${car.id}`;
    }

    return requests.get(url);
  }
};
export default FuelServices;
