import Cookies from "js-cookie";
import { createContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// create context
export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [lang, setLang] = useState("en");
    const [windowDimension, setWindowDimension] = useState(window.innerWidth);
    const [navBar, setNavBar] = useState(true);
    const { i18n } = useTranslation();

    const closeSidebar = () => setIsSidebarOpen(false);
    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

    const closeDrawer = () => setIsDrawerOpen(false);
    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

    const closeModal = () => setIsModalOpen(false);
    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const handleLanguageChange = (lang) => {
        Cookies.set("i18next", lang);
        i18n.changeLanguage(lang);
        setLang(lang);
    };

    useEffect(() => {
        const lang = Cookies.get("i18next");
        if (lang === "en-US" || lang === "en-GB") {
            setLang("en");
        } else if(lang === "fr-FR"){
            setLang("fr");
        } else {
            setLang(Cookies.get("i18next"));
        }
        console.log("languageAAA " + lang)
    }, [windowDimension]);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <SidebarContext.Provider
            value={{
                isSidebarOpen,
                toggleSidebar,
                closeSidebar,
                isDrawerOpen,
                toggleDrawer,
                closeDrawer,
                setIsDrawerOpen,
                isModalOpen,
                toggleModal,
                closeModal,
                isUpdate,
                setIsUpdate,
                lang,
                setLang,
                handleLanguageChange,
                windowDimension,
                setNavBar,
                navBar,
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};
