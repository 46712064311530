export const USER_INFO = "GVEO_WEB_CLIENT_INFO";
export const SELECTED_CAR_INDEX = "GVEO_SELECTED_CAR_INDEX";
export const CARS = "GVEO_WEB_CARS_INFO";
export const REGISTER = "REGISTER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

// for roles
export const CLIENT = "client";
export const DRIVER = "driver";
export const EMPLOYEE = "employee";
