import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {LiaArrowLeftSolid} from "react-icons/lia";
import Skeleton from "react-loading-skeleton";
import dayjs from "dayjs";
import {HiBars3} from "react-icons/hi2";
import {useSelector} from "react-redux";
import {selectedCar} from "../../utils";
import ReparationServices from "../../services/ReparationServices";
import FuelServices from "../../services/FuelServices";

const Fuel = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const [fuelListData, setFuelListData] = useState([] || {});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  // console.log("id params", id)

  useEffect(() => {
    loadFuelList()
  }, []);

  function loadFuelList () {
    setError("")
    setLoading(true)
    FuelServices
        .getAllFuel(car)
        .then(res => setFuelListData(res?.data))
        .catch(err => setError(err.message))
        .finally(()=> setLoading(false))
  }

  return (
      <>
        <div className="flex  items-center py-3   bg-primary">
          <div
            onClick={() => navigate(-1)}
            className="pl-4 pr-8 cursor-pointer"
          >
            <LiaArrowLeftSolid className="text-white text-2xl"/>
          </div>
          <div className="font-myfont text-base md:text-lg text-white">
            Dernière opération
          </div>
        </div>

        {loading && (
            <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-6 px-6 my-4">
              {[1, 2].map(item => {
                return (
                    <div key={item}>
                      <Skeleton
                          count={4}
                          height={140}
                          className="bg-gray-600"
                      />
                    </div>
                )
              })}
            </div>
        )}

        {(!loading && error.length !== 0) && (
            <div className="flex-1 h-full flex flex-col justify-center items-center text-red-500 gap-4 my-4">
              <div className="text-center">Une erreur est survenu veuillez réessayer</div>
              <button
                  onClick={loadFuelList}
                  className="uppercase px-8 py-1.5 rounded hover:bg-red-500/10 active:bg-red-500/20 focus:outline-none border-2 border-red-500">
                réessayer
              </button>
            </div>
        )}

        {(!loading && error.length === 0 && fuelListData?.data?.length !== 0) && (
            <div className="bg-white flex-1 flex flex-col justify-start items-center overflow-y-auto">
              <div className="w-full flex flex-col px-3 mt-2 mb-4 justify-center items-center">
                <div className="mt-4 w-full grid grid-cols-1 lg:grid-cols-2 gap-3">
                  {fuelListData?.data?.map((item, index) => {
                    return (
                        <div
                            key={index.toString()}
                            className="w-full flex flex-col bg-white lg:max-w-[450px] flex-wrap shadow-lg border rounded-lg">

                            <div className="flex-1 flex gap-2 items-center px-4">
                                <div className="w-[40px] h-[50px]">
                                    <img className="w-full h-full object-contain"
                                         src={`${process.env.REACT_APP_BASE_URL}/${item?.vehicle?.brand?.icon}`}
                                         alt="car"/>
                                </div>
                                <div className="flex flex-col justify-start items-start ">
                                    <p className="text-base ">{item.vehicle?.plate_number}</p>
                                    <p className="text-sm text-gray-500">{item?.driver ? `${item.driver?.first_name} ${item.driver?.last_name}` : "pas de chauffeur"}</p>
                                </div>
                            </div>

                            <div className="w-full h-[0.5px] bg-gray-400 my-2"/>

                            <div className="px-4">
                                <div className="flex gap-4 border-b flex-wrap py-2 items-center">
                                    <div className="flex-1 text-gray-500 capitalize">
                                        Consommation
                                    </div>
                                    <div className="flex-none text-black">
                                        {item.quantity} litres
                                    </div>
                                </div>
                                <div className="flex gap-4 border-b flex-wrap py-2 items-center">
                                    <div className="flex-1 text-gray-500 capitalize">prix</div>
                                    <div className="flex-none text-black">
                                        {item.price} FCFA
                                    </div>
                                </div>
                                <div className="flex gap-4 border-b flex-wrap py-2 items-center">
                                    <div className="flex-1 text-gray-500 capitalize pr-4">station</div>
                                    <div className="text-black">
                                        {item.gas_station}
                                    </div>
                                </div>
                                <div className="flex gap-4 border-b flex-wrap py-2 items-center">
                                    <div className="flex-1 text-gray-500 capitalize pr-4">
                                        Kilométrage a la date
                                    </div>
                                    <div className="text-black">
                                        {item.current_mileage} km
                                    </div>
                                </div>
                                <div className="flex gap-4 flex-wrap py-2 items-center">
                                    <div className="flex-1 text-gray-500 capitalize pr-4">Date</div>
                                    <div className="text-black">
                                        {dayjs(item.date).format("DD MMM YYYY")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                  })}
                </div>

              </div>
            </div>
        )}

          {(!loading && error.length === 0 && fuelListData?.data?.length === 0) && (
              <div className="bg-white flex-1 flex flex-col justify-center items-center overflow-y-auto">
                  <div
                      className="w-full flex flex-col gap-2 px-3 mt-2 mb-4 text-center text-gray-500 justify-center items-center">
                      <HiBars3 className="text-[150px] text-gray-300"/>
                      <div className="max-w-md font-semibold">
                          Vous n'avez aucune réparation enregistrée
                          {/*pour transmission et pneumatique*/}
                      </div>
                  </div>
              </div>
          )}

          <button
              className="flex justify-center items-center py-3 bg-primary text-white hover:bg-hoverBleu-200 active:bg-primary"
              onClick={() => navigate("/fuel_new", {
                  replace: false,
                  // state: {
                  //   reparation: selectedReparation,
                  // },
              })}
          >
              Enregistrer
          </button>
      </>
  )
};

export default Fuel;
