import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {useDispatch, useSelector} from "react-redux";
import {fetchCars, removeCar, setCarAction, unSelectCar} from "../../redux-toolkit/carManagementSlice";
import MainDialog from "../dialog/MainDialog";
import DeleteCarDialog from "../dialog/DeleteCarDialog";
import CarServices from "../../services/CarServices";
import {notifyError} from "../../utils/toast";
import {selectedCar} from "../../utils";
import useLongPress from "../../utils/longPress";
import {CLIENT} from "../../pref";
import {MdWarning, MdWarningAmber} from "react-icons/md";

const SidebarContent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authSlice.userInfo);
  const loadCars = () => dispatch(fetchCars(user?.data?.uuid));
  const cars = useSelector((state) => state.carManagementSlice.data);
  const loading = useSelector((state) => state.carManagementSlice.loading);
  const error = useSelector((state) => state.carManagementSlice.error);
  const car = selectedCar(cars)

  const [carsFilter,setCarsFilter] = useState([])

  useEffect(() => {
    if(cars) {
      setCarsFilter([...cars])
    }
  }, [cars]);

  const [isOpen, setIsOpen] = useState(false);
  const handleCloseDialog = () => setIsOpen(false)
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [chooseCar, setChooseCar] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const handleIsEdit = () => setIsEdit(old => !old)

  const [show, setShow] = useState(false);
  const handleShow = () => setShow((old) => !old);

  const menu = [
    {
      icon: "fa-user",
      title: "utilisateurs",
      route: "/employee",
    },

    {
      icon: "fa-solid fa-gas-pump",
      title: "carburation",
      route: "/carburation",
    },
    {
      icon: "fa-record-vinyl",
      title: "Départ / Arrivée",
      route: "/chechin_checkout",
    },
    {
      icon: "fa-solid fa-file-excel",
      title: "Pannes",
      route: "/breakdown",
    },
    // {
    //   icon: "fa-triangle-exclamation",
    //   title: "Alerte",
    //   route: "/alert",
    // },
    {
      icon: "fa-solid fa-chart-simple",
      title: "statistique",
      route: "/statistics",
    },
    // {
    //   icon: "fa-shield-halved",
    //   title: "Sécurité Routière",
    //   route: "/road_safety",
    // },
    {
      icon: "fa-gear",
      title: "Paramètres",
      route: "/setting",
    },
  ];

  const menuEmployee = [
    {
      icon: "fa-gear",
      title: "Paramètres",
      route: "/setting",
    },
  ];

  const selectCar = (index) => {
    dispatch(setCarAction(index))
  }

  const onLongPress = useLongPress();

  function onSubmit (e) {
    setLoadingDelete(true);

    CarServices.deleteCar(chooseCar?.id)
      .then((res)=> {
        if(car?.id === chooseCar?.id){
          dispatch(unSelectCar())
        }
        handleCloseDialog();
        dispatch(removeCar(car?.id));
      })
      .catch((res) => notifyError(res?.response?.data?.message))
      .finally(()=> setLoadingDelete(false))
  }

  return (
      <>
        <div className=" flex flex-col border-r-[2px] max-w-[350px] bg-white w-full h-screen ">
          <div className="flex-none bg-primary">
            <div
                onClick={handleShow}
                className="flex-wrap cursor-pointer flex justify-between "
            >
              <div className="logo text-[#ffffff] text-[20px] px-4">
                <span className="text-[35px] text-bold text-[#f94848]">G</span>veo
              </div>
              <div className="down-drop-bars text-[white] p-3 px-4">
                <i className="fa-solid fa-circle-chevron-down"></i>
              </div>
            </div>
            {show && (
                <div className="px-2">
                  {(user?.data?.roles[0]?.name === CLIENT ? menu : menuEmployee).map((element, index) => {
                    return (
                        <div
                            key={index.toString()}
                            onClick={() => {
                              navigate(element.route);
                            }}
                            className="text-[white] hover:bg-[#f94848] rounded duration-500 transition-all my-2 py-2 cursor-pointer capitalize"
                        >
                          <i className={"mx-2 fa-solid " + element.icon}/>{" "}
                          {element.title}
                        </div>
                    );
                  })}
                </div>
            )}
          </div>
          <div className="h-full flex-1 flex flex-col">
            <div className="w-full flex gap-2 items-center p-2">
              <div className="flex-1 bg-white flex items-center px-3 py-2 rounded-md shadow">
                <label htmlFor="r">
                  <i className="fa-solid fa-magnifying-glass"></i>
                </label>
                <input
                    id="r"
                    type="text"
                    className="ml-3 w-full p-1 focus:outline-none"
                    placeholder="Rechercher un véhicule"
                    onChange={(e) => {
                      const entrie = e.target.value
                      if (entrie === "") {
                        setCarsFilter([...cars])
                      } else {
                        const filter = cars.filter(item => item.car?.plate_number?.toLowerCase().includes(entrie.toLowerCase()))
                        setCarsFilter([...filter])
                      }
                    }}
                />
              </div>
              {/*{user?.data?.roles[0]?.name === CLIENT && (*/}
              {/*  <button*/}
              {/*      onClick={() => handleIsEdit()}*/}
              {/*      className="flex-none w-fit h-fit px-3 py-4 text-white bg-primary hover:bg-primary/85 focus:bg-primary/85 active:bg-primary rounded">*/}
              {/*    {isEdit ? <FaCheck/> : <GrEdit/>}*/}
              {/*  </button>*/}
              {/*)}*/}
            </div>
            <div className="flex-1">
              {loading && (
                  <div className="w-full flex justify-center ">
                    <Skeleton
                        count={8}
                        height={60}
                        width={300}
                      className="bg-gray-300 "
                  />
                </div>
              )}

              {(!loading && error.length !== 0) && (
                  <div
                      className="flex-1 h-full flex flex-col gap-4 justify-center items-center font-semibold text-red-500 px-4">
                    <div className="text-center">Une erreur est survenu veuillez réessayer</div>
                    <button
                        onClick={loadCars}
                        className="uppercase px-8 py-1.5 rounded hover:bg-red-500/10 active:bg-red-500/20 focus:outline-none border-2 border-red-500">
                      réessayer
                    </button>
                  </div>
              )}

              {(!loading && error.length === 0) && (
                  <div
                      className={["w-full flex-1 h-full flex items-center", carsFilter?.length === 0 ? "flex-col px-1" : "justify-start flex-col px-2"].join(" ")}
                  >
                    {carsFilter?.length !== 0 ? (
                        carsFilter?.map((element, index) => {
                          const myCar = element?.car

                          return (
                              <div
                                  key={index.toString()}
                                  className="w-full flex items-center">
                                <button
                                    key={myCar?.id}
                                    onClick={() => {
                                      if(car?.id !== myCar?.id) {
                                        selectCar(index)
                                        // navigate("/")
                                      }
                                    }}
                                    {...onLongPress(() => {
                                      if(user?.data?.roles[0]?.name === CLIENT){
                                        setChooseCar(myCar)
                                        setIsOpen(true)
                                      }
                                    })}
                                    className="flex-1 w-full hover:bg-slate-200 active:bg-primary focus:outline-none p-2 rounded-lg">
                                  <div className="h-full flex items-center gap-2">
                                    <div
                                        className={`flex-none w-2 h-12 bg-primary ${element.selected ? 'bg-primary' : 'bg-transparent'}`}/>
                                    <div className="flex-1 flex gap-2 items-center">
                                      <div className="w-[40px] h-[50px]">
                                        <img className="w-full h-full object-contain"
                                             src={`${process.env.REACT_APP_BASE_URL}/${myCar?.brand?.icon}`}
                                             alt="car"/>
                                      </div>
                                      <div className="flex flex-col justify-start items-start ">
                                        <p className="text-base ">{myCar?.plate_number}</p>
                                        <p className="text-sm text-gray-500">{myCar?.driver ? `${myCar.driver?.first_name} ${myCar.driver?.last_name}` : "pas de chauffeur"}</p>
                                      </div>
                                    </div>
                                    <div className="flex-none">
                                      {myCar.alert && (
                                          <MdWarning className="text-red-500 text-xl"/>
                                      )}
                                      {myCar.preventive_alert && (
                                          <MdWarningAmber className="text-red-500 text-xl"/>
                                      )}
                                    </div>
                                  </div>
                                </button>
                              </div>
                          );
                        })
                    ) : (
                        <div className="flex-1 flex flex-col justify-center items-center">
                          <i className="fa-solid fa-car text-[95px] text-[#b4b4b4] font-bold"></i>
                          <p className="text-[#b4b4b4] my-5 text-center font-bold">
                            Vous n'avez aucun véhicule enregistré
                          </p>
                          <a
                              onClick={() => navigate(`/car_new`)}
                          >
                            <button
                                type="button"
                                className="py-3 px-5 bg-[#0D506E] hover:bg-[#f94848] font-semibold text-[white] rounded text-sm"
                            >
                              Ajouter un véhicule
                              <i className="ml-2 fa-solid fa-circle-plus"></i>
                            </button>
                          </a>
                        </div>
                    )}
                  </div>
              )}
            </div>


            {(carsFilter?.length !== 0 && user?.data?.roles[0]?.name === CLIENT) && (
                <div className=" flex items-center justify-center px-2 py-2">
                  <button
                      type="button"
                      onClick={() => navigate(`/car_new`)}
                      className="w-full py-3 px-5 bg-primary flex justify-center items-center hover:bg-hoverBleu-200 font-semibold text-[white] rounded text-sm hover:outline-none">
                    <p>
                      Ajouter un véhicule
                    </p>
                    <i className="ml-2 fa-solid fa-circle-plus"></i>
                  </button>
                </div>
            )}
          </div>
        </div>
        <MainDialog className="max-w-[400px]" closeModal={handleCloseDialog} isOpen={isOpen}>
          <DeleteCarDialog
            closeModal={handleCloseDialog}
            onSubmit={onSubmit}
            loading={loadingDelete}
            car={chooseCar}/>
        </MainDialog>
      </>
  );
};

export default SidebarContent;
