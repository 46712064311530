import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectedCar} from "../../utils";
import BreakDownServices from "../../services/BreakdownServices";
import {CLIENT} from "../../pref";
import {HiBars3} from "react-icons/hi2";
import {LiaArrowLeftSolid} from "react-icons/lia";
import Skeleton from "react-loading-skeleton";

const Breakdown = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const cars = useSelector((state) => state.carManagementSlice.data);
    const car = selectedCar(cars)

    const [breakdownListData, setBreakdownListData] = useState([] || {});
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    // console.log("id params", id)

    const user = useSelector((state) => state.authSlice.userInfo);

    useEffect(() => {
        if(!car?.id){
            navigate("/")
        }else{
            loadBreakdownList()
        }
    }, []);

    function loadBreakdownList () {
        setError("")
        setLoading(true)
        BreakDownServices
            .getAllBreakDown(car?.id)
            .then(res => setBreakdownListData(res?.data))
            .catch(err => setError(err.message))
            .finally(()=> setLoading(false))
    }

    return (
        <>
            <div className="flex  items-center py-3   bg-primary">
                <div
                    onClick={() => navigate(-1)}
                    className="pl-4 pr-8 cursor-pointer"
                >
                    <LiaArrowLeftSolid className="text-white text-2xl"/>
                </div>
                <div className="font-myfont text-base md:text-lg text-white">
                    Pannes
                </div>
            </div>

            {loading && (
                <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-6 px-6 my-4">
                    {[1, 2].map(item => {
                        return (
                            <div key={item}>
                                <Skeleton
                                    count={4}
                                    height={140}
                                    className="bg-gray-600"
                                />
                            </div>
                        )
                    })}
                </div>
            )}

            {(!loading && error.length !== 0) && (
                <div className="flex-1 h-full flex flex-col justify-center items-center text-red-500 gap-4 my-4">
                    <div className="text-center">Une erreur est survenu veuillez réessayer</div>
                    <button
                        onClick={loadBreakdownList}
                        className="uppercase px-8 py-1.5 rounded hover:bg-red-500/10 active:bg-red-500/20 focus:outline-none border-2 border-red-500">
                        réessayer
                    </button>
                </div>
            )}

            {(!loading && error.length === 0 && breakdownListData?.data?.length !== 0) && (
                <div className="bg-white flex-1 flex flex-col justify-start items-center overflow-y-auto">
                    <div className="w-full flex flex-col px-3 mt-2 mb-4 justify-center items-center">
                        <div className="mt-4 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-start gap-3">
                            {breakdownListData?.data?.map((item, index) => {
                                return (
                                    <button
                                        key={index.toString()}
                                        onClick={() => navigate("/breakdown_repair", {
                                            replace: false,
                                            state: {
                                                breakdown: item,
                                            },
                                        })}
                                        className="w-full flex flex-col justify-start items-center mx-auto hover:bg-gray-100 active:bg-gray-300">
                                        <div
                                            className="flex items-center gap-2 py-3">
                                            <div className="w-[50px] h-[50px]">
                                                <img
                                                    alt="ico"
                                                    className="w-full"
                                                    src={`${process.env.REACT_APP_BASE_URL}/${item?.system_component?.system?.thumburl}`}/>
                                            </div>
                                            <div className="flex flex-col justify-start items-start my-auto">
                                                <p className="text-primary font-semibold font-myfont">
                                                    {item?.system_component?.label}
                                                </p>
                                                <p className="md:text-sm text-xs text-mygrey">
                                                    {item?.system_component?.system?.label}
                                                </p>
                                                <hr className="w-full mt-2"/>
                                            </div>
                                        </div>
                                    </button>
                                )
                            })}
                        </div>

                    </div>
                </div>
            )}

            {(!loading && error.length === 0 && breakdownListData?.data?.length === 0) && (
                <div className="bg-white flex-1 flex flex-col justify-center items-center overflow-y-auto">
                    <div
                        className="w-full flex flex-col gap-2 px-3 mt-2 mb-4 text-center text-gray-500 justify-center items-center">
                        <HiBars3 className="text-[150px] text-gray-300"/>
                        <div className="max-w-md font-semibold">
                            Vous n'avez aucune pannes enregistrée
                            {/*pour transmission et pneumatique*/}
                        </div>
                    </div>
                </div>
            )}

            {user?.data?.roles[0]?.name === CLIENT && (
                <button
                    className="flex justify-center items-center py-3 bg-primary text-white hover:bg-hoverBleu-200 active:bg-primary"
                    onClick={() => navigate("/breakdown_new")}
                >
                    Enregistrer
                </button>
            )}
        </>
    );
}

export default Breakdown;