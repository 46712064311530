import requests from "./httpService";

const CarServices = {
  addCar: async (body) => {
    return requests.post(`/vehicles`, body);
  },

  getCar: async (id) => {
    return requests.get(`/vehicles/user/${id}`);
  },

  getCarType: async () => {
    return requests.get(`/vehicle_types`);
  },

  getEngineType: async () => {
    return requests.get(`engines`);
  },

  getCarTransmission: async () => {
    return requests.get(`transmissions`);
  },

  getCarBrand: async () => {
    return requests.get(`brands`);
  },

  updateMileage: async (body) => {
    return requests.post(`/mileage-logs`, body);
  },

  getCheckInCheckOut: async (carId) => {
    let url = "vehicle-mvts"

    if(carId !== null){
      url = `${url}/vehicles/${carId}`;
    }

    return requests.get(url);
  },

  getAllCarMvtType: async () => {
    return requests.get("/mvt-types");
  },

  getAllCarSystem: async () => {
    return requests.get( `/vehicle_systems`);
  },

  getAllCarSystemComponents: async (id) => {
    return requests.get( `/system_components/system/${id}`);
  },

  deleteCar: async (carId) => {
    return requests.delete(`/vehicles/${carId}`);
  },
};
export default CarServices;
