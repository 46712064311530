import OtpForm from "../../components/auth/OtpForm";
import {REGISTER} from "../../pref";
import React from "react";
import useOtp from "../../hooks/useOtp";

export default function Otp() {
  const {
    type,
    phone,
    loading,
    error,
    code,
    handleCode,
    codeValidator
  } = useOtp();

  return (
    <>
      <div className="w-full font-myfont min-h-screen py-1  flex-col bg-primary flex items-center">
        <div className="max-w-[1500px] w-full flex flex-col justify-center items-center mt-10">
          <div className="max-w-[1500px] flex flex-col items-center ">
            <div className="text-md mx-6 text-white font-bold uppercase">
              {type === REGISTER ? "Inscription" : "mot de passe oublié"}
            </div>
            <p className=" text-sm mt-1 text-white/70 font-semibold text-center">
              Nous avons envoyé le code de confirmation sur le numéro suivant: <br/> {`+237${phone}`}
            </p>
          </div>
          <OtpForm
            loading={loading}
            error={error}
            code={code}
            handleCode={handleCode}
            codeValidator={codeValidator}/>
        </div>
      </div>
    </>
  );
}
