import React from "react";
import BoxUtilisation from "../../components/box/BoxUstilisation";
import { Doughnut } from "react-chartjs-2";
import 'chart.js/auto';
import Main from "../../layout/Main";
import useUtilisation from "../../hooks/useUtilisation";
import Skeleton from "react-loading-skeleton";
import MainDialog from "../../components/dialog/MainDialog";
import UtilisationSortDialog from "../../components/dialog/UtilisationSortDialog";
import {Bar, LineChart, CartesianGrid, ResponsiveContainer, Legend, Tooltip, XAxis, YAxis, Line} from "recharts";

  const Utilisation = () => {
    const {
      period,
      filterTitle,
      filterValue,
      isWrap,
      isOpen,
      handleWrap,
      MouseOver,
      MouseOut,
      statsData,
      loadingStats,
      error,
      car,
      handlePeriod,
      chartData,
      handleOpenDialog,
      handleCloseDialog,
      from,
      setFrom,
      to,
      setTo,
      onSubmit,
      costData,
      loadingCost,
      errorCost,
    } = useUtilisation(false)

    function graphList(){
      return costData?.fuel_consumption?.months?.map((item, index)=>{
        return {
          month: item.key,
          fuel: item.value?.total_cost,
          repair: costData?.repair_cost?.months[index].value?.total_cost,
          maintenance: costData?.maintenance_cost?.months[index].value?.total_cost
        }
      })
    }

    return (
        <Main selected="Utilisation">
          <div className="flex-1 w-full h-full overflow-y-auto">
            <div className="w-full flex text-white justify-center flex-col px-2 md:px-6">
              <div className="flex-none text-center my-2 p-2 md:p-4 rounded-lg bg-white shadow-lg border-gray-300 border">
                <div className="uppercase text-primary font-bold text-md md:text-xl">
                  statistique d'utilisation
                </div>
                <p className="text-gray-500 text-sm md:text-base">
                  Les données importantes à relever sur l'utilisation du véhicule en
                  termes de coûts et de distances
                </p>
              </div>
            </div>

            {loadingStats && (
              <div className="cards grid grid-cols-1 md:grid-cols-2 gap-6 px-2 md:px-6">
                {[1, 2].map(item => {
                  return (
                      <div key={item}>
                        <Skeleton
                            count={1}
                            height={450}
                            className="bg-gray-600"
                        />
                      </div>
                  )
                })}
              </div>
            )}

            {(!loadingStats && error.length !== 0) && (
              <div
                  className="flex-1 h-full flex flex-col gap-4 justify-center items-center font-semibold text-red-500 px-4">
                <div className="text-center">Une erreur est survenu veuillez réessayer</div>
                <button
                    onClick={loadingStats}
                    className="uppercase px-8 py-1.5 rounded hover:bg-red-500/10 active:bg-red-500/20 focus:outline-none border-2 border-red-500">
                  réessayer
                </button>
              </div>
            )}

            {(!loadingStats && error.length === 0) && (
              <>
                <div className="flex gap-2 mx-3 md:mx-6 text-white font-semibold">
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}/reports/pdf/vehicles/${car?.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h-10 w-full flex-1 flex justify-center items-center bg-green-500 rounded shadow-xl hover:bg-green-600 active:bg-green-500">
                    Exporter en PDF
                  </a>
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}/reports/xlsx/vehicles/${car?.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h-10 w-full flex-1 flex justify-center items-center bg-green-500 rounded shadow-xl hover:bg-green-600 active:bg-green-500">
                    Exporter en Xlsx
                  </a>
                </div>
                <div className="mt-3 h-[500px] grid md:grid-cols-2 md:grid-rows-1 bg-gray-100 mx-3 md:mx-6">
                  <div className="h-full bg-white flex-col shadow-sm pb-2">
                    <div className="relative flex px-2 justify-between items-center bg-primary">
                      <p className="text-white font-bold py-2 md:py-3">
                        Détails et coûts
                      </p>
                      <BoxUtilisation
                          isWrap={isWrap}
                          filterTitle={filterTitle}
                          filterValue={filterValue}
                          handleWrap={handleWrap}
                          MouseOver={MouseOver}
                          MouseOut={MouseOut}
                          period={period}
                          handlePeriod={handlePeriod}
                          handleOpenDialog={handleOpenDialog}
                      />
                    </div>
                    <div className="flex flex-col">
                      <div className="mx-2 flex justify-between border-b py-5">
                        <p className="text-black">
                          Distance parcourue
                        </p>
                        <p className="text-gray-500">{car?.mileage}</p>
                      </div>
                      <div className="mx-2 flex justify-between border-b py-5">
                        <p className="text-black">
                          Maintenance préventive
                        </p>
                        <p className="text-gray-500">{statsData?.data?.preventive_fees}</p>
                      </div>
                      <div className="mx-2 flex justify-between border-b py-5">
                        <p className="text-black text-base md:text-lg">
                          Maintenance curvative
                        </p>
                        <p className="text-gray-500">{statsData?.data?.currative_fees}</p>
                      </div>
                      <div className="mx-2 flex justify-between border-b py-5">
                        <p className="text-black">
                          Dépenses administratives
                        </p>
                        <p className="text-gray-500">{statsData?.data?.document_fees}</p>
                      </div>
                      <div className="mx-2 flex justify-between border-b py-5">
                        <p className="text-black">
                          Consommation du carburant
                        </p>
                        <p className="text-gray-500">{statsData?.data?.fuel_fees}</p>
                      </div>
                      <div className="mx-2 flex justify-between border-b py-5">
                        <p className="text-black">Coût total de maintenance</p>
                        <p className="text-gray-500">{statsData?.data?.total_fees}</p>
                      </div>
                    </div>
                  </div>

                  <div
                      className="h-full bg-white flex-col rounded-br-md shadow-sm mb-3 md:mb-5 border-l-[1px] pb-4 md:pb-8">
                    <div className="flex justify-start bg-primary">
                      <p className="text-white font-myfont pl-2 py-2 md:py-3">
                        Repartition des coûts
                      </p>
                    </div>

                    <div className="h-full mx-2 flex-col items-center justify-center">
                      <Doughnut data={chartData} className="max-w-[400px]"/>
                    </div>
                  </div>
                </div>
              </>
            )}

            {loadingCost && (
              <div className="cards grid grid-cols-1 md:grid-cols-2 gap-6 px-2 md:px-6">
                {[1, 2].map(item => {
                  return (
                    <div key={item}>
                      <Skeleton
                        count={1}
                        height={450}
                        className="bg-gray-600"
                      />
                    </div>
                  )
                })}
              </div>
            )}

            {(!loadingCost && errorCost.length === 0) && (
              <div className="h-full md:mt-36 border-4">
                <ResponsiveContainer width="90%" height={400}>
                  <LineChart className="h-full border pb-0" data={graphList()}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="month" stroke="#5a5b65"/>
                    <YAxis/>
                    <Tooltip />
                    {/*<Tooltip wrapperStyle={{width: 100, backgroundColor: '#000'}}/>*/}
                    <Legend width={220} wrapperStyle={{
                      top: -90,
                      right: 10,
                      backgroundColor: '#f5f5f5',
                      border: '1px solid #d5d5d5',
                      borderRadius: 3,
                      color: '#F5811E',
                      lineHeight: '40px'
                    }}/>
                    <Line legendType='rect' type="monotone" dataKey="fuel" stroke="#000000" activeDot={{ r: 8 }} />
                    <Line legendType='rect' type="monotone" dataKey="repair" stroke="#8a0619" activeDot={{ r: 8 }} />
                    <Line legendType='rect' type="monotone" dataKey="maintenance" stroke="#33cae8" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>

          <MainDialog className="max-w-[400px]"
                      closeModal={handleCloseDialog} isOpen={isOpen}>
            <UtilisationSortDialog
                closeModal={handleCloseDialog}
                handleCloseDialog={handleCloseDialog}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                onSubmit={onSubmit}/>
          </MainDialog>
        </Main>
    );
  };

export default Utilisation;
