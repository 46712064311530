import { useEffect, useState } from "react";
import { FORGOT_PASSWORD, REGISTER, USER_INFO } from "../pref";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthServices from "../services/AuthServices";
import { join } from "lodash-es";
import { Navigate } from "react-router-dom";

const usePhoneNumber = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [searchParams, setSearchParams] = useSearchParams({ type: "" });
  const [type, setType] = useState(searchParams.get("type") ?? REGISTER);

  const clearError = () => setError("")

  useEffect(() => {
    if (searchParams.get("type") === REGISTER) {
      setType(REGISTER);
    } else if (searchParams.get("type") === FORGOT_PASSWORD) {
      setType(FORGOT_PASSWORD);
    } else {
      navigate("/");
    }
  }, []);

  const initialValues = {
    phone: "",
  };

  const validationSchema = yup.object({
    phone: yup
      .string()
      .required("Veuillez entrer votre numéro de téléphone")
      .matches(/^[0-9]+$/, "Numéro de téléphone invalide")
      .min(9, "Doit comporter exactement 9 chiffres")
      .max(9, "Doit comporter exactement 9 chiffres"),
  });

  const onSubmit = (e) => {
    setLoading(true);
    console.log(e.phone);

    AuthServices.verifyNumber(`${e.phone}`)
      .then((res) => {
        console.log(res?.data)
        if (res?.data?.success) {
          console.log(res.data.message, e.phone);
          navigate(`/otp?type=${REGISTER}&phone=${e.phone}`);
        } else {
          setError(res.data.message)
          // console.log(res.data.message);
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    type,
    formik,
    loading,
    error,
    clearError
  };
};

export default usePhoneNumber;
