import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import Cookies from "js-cookie";
import {USER_INFO} from "../pref";

const AuthSlice = createSlice({
    name: 'Auth',
    initialState: {
        userInfo: Cookies.get(USER_INFO)
            ? JSON.parse(Cookies.get(USER_INFO))
            : null,
    },
    reducers: {
        setUser: (state, action) => {
            state.userInfo = action.payload
        },
        logout: (state) => {
            state.userInfo = null
        },
    }
});
export const { logout, setUser } = AuthSlice.actions
export default AuthSlice.reducer;