import {useEffect, useState} from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useAsync from "./useAsync";
import AuthServices from "../services/AuthServices";
import ReparationServices from "../services/ReparationServices";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectedCar} from "../utils";
import dayjs from "dayjs";
import {notifyError} from "../utils/toast";
import DocumentService from "../services/DocumentService";

const useEmployeeForm = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const cars = useSelector((state) => state.carManagementSlice.data);
    const car = selectedCar(cars)

    const {
        loading: loadingRole,
        data: roleData,
        error: errorRole
    } = useAsync(() => AuthServices.getAllRoles());

    // console.log("roleData ", roleData)

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handlePassword = () => setShowPassword(old => !old)
    const initialValues = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        password: "",
        role: ""
    };

    const validationSchema = yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        phoneNumber: yup.number().required(),
        password: yup.string().required(),
        role: yup.string().required(),
    });

    const onSubmit = (e) => {
        setLoading(true);

        const body = {
            "first_name" : e.firstName,
            "last_name" : e.lastName,
            "phone" : `+237${e.phoneNumber}`,
            "password" : e.password,
            "role": e.role
        }

        AuthServices.addUsers(body)
            .then((res)=> {
                if(res.data.success){
                    navigate(-1)
                }else{
                    notifyError(res?.data?.message ?? "une erreur est survenu veuillez reessayer plutard!")
                }
            })
            .catch((res) => notifyError(res?.response?.data?.message))
            .finally(()=> setLoading(false))
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return {
        formik,
        loading,
        navigate,
        showPassword,
        handlePassword,
        roleData
    };
};

export default useEmployeeForm;
