import {useEffect, useRef} from "react";

export function selectedCar(cars){
    let selectedCar = null;

    if(cars){
        cars?.forEach(item => {
            if(item.selected){
                selectedCar = item?.car
            }
        })
    }


    return selectedCar;
}

export function useOnceCall(cb, condition = true) {
    const isCalledRef = useRef(false);

    useEffect(() => {
        if (condition && !isCalledRef.current) {
            isCalledRef.current = true;
            cb();
        }
    }, [cb, condition]);
}