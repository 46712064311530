import ErrorForm from "../form/Error";
import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import useCarNew from "../../hooks/useCarNew";
import InputFormik from "../form/InputFormik";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import SelectFormik from "../form/selectFormik";

const CarNewForm = () => {
  const navigate = useNavigate();

  const {
    formik,
    loading,
    driverData,
    carTypeData,
    engineTypeData,
    carTransmissionData,
    carBrandData,
    carColors
  } = useCarNew();

  return (
      <form onSubmit={formik.handleSubmit} className="mt-8 relative flex flex-col gap-6">
        {driverData?.data?.length !== 0 && (
          <div>
            <div className="flex-col w-fullv flex w-full text-white border-b-2 border-solid border-white">
              <label
                  className="text-white/60 relatve text-[0.75em]  pointer-events-none"
                  htmlFor="type-v"
              >
                Sélectionnez un conducteur
              </label>
              <select
                  value={formik.values.driver}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full focus:outline-none  bg-transparent"
                  name="driver">
                <option
                    hidden={true}
                    className="text-black"
                    value="0">
                  Sélectionnez un conducteur
                </option>
                {driverData?.data?.map((item, index) => {
                  return (
                      <option
                          key={index}
                          className="text-black"
                          value={item?.uuid}>
                        {item?.first_name + ' ' + item?.last_name}
                      </option>
                  )
                })}
              </select>
            </div>
            {formik.touched.driver && formik.errors.driver && (
              <ErrorForm message={formik.errors.driver}/>
            )}
          </div>
        )}

        {carTypeData?.data?.length !== 0 && (
            // <SelectFormik
            //     variant="standard"
            //     onChange={formik.handleChange}
            //     onBlur={formik.handleBlur}
            //     error={formik.touched.cartType && formik.errors.cartType}
            //     helperText={formik.touched.cartType && formik.errors.cartType}
            //     value={formik.values.cartType}
            //     name="cartType"
            //     label="Sélectionnez un type de véhicule">
            //     {carTypeData?.data?.map((item, index) => {
            //         return (
            //             <MenuItem key={index} value={item?.id}>
            //                 {item?.label}
            //             </MenuItem>
            //         )
            //     })}
            // </SelectFormik>
            <div>
              <div className="flex-col w-fullv flex w-full text-white border-b-2 border-solid border-white">
                <label
                  className="text-white/60 relatve text-[0.75em]  pointer-events-none"
                  htmlFor="type-v"
                >
                  Type de véhicule
                </label>
                <select
                    name="cartType"
                    value={formik.values.cartType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full focus:outline-none bg-transparent">
                  <option
                      hidden={true}
                      className="text-black"
                      value="0">
                    Sélectionnez un type de véhicule
                  </option>
                  {carTypeData?.data?.map((item, index) => {
                    return (
                      <option
                        key={index}
                        className="text-black"
                        value={item?.id}>
                        {item?.label}
                      </option>
                    )
                  })}
                </select>
              </div>
              {formik.touched.cartType && formik.errors.cartType && (
                <ErrorForm message={formik.errors.cartType}/>
              )}
            </div>
        )}

        {engineTypeData?.data?.length !== 0 && (
            <div>
              <div className="flex-col w-fullv flex w-full text-white border-b-2 border-solid border-white">
                <label
                    className="text-white/60 relatve text-[0.75em]  pointer-events-none"
                    htmlFor="type-v"
                >
                  Type de moteur
                </label>
                <select
                    name="engineType"
                    value={formik.values.engineType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full focus:outline-none  bg-transparent">
                  <option
                      hidden={true}
                      className="text-black"
                      value="0">
                    Sélectionnez un type de moteur
                  </option>
                  {engineTypeData?.data?.map((item, index) => {
                    return (
                      <option
                        key={index}
                        className="text-black"
                        value={item?.id}>
                        {item?.label}
                      </option>
                    )
                  })}
                </select>
              </div>
              {formik.touched.engineType && formik.errors.engineType && (
                <ErrorForm message={formik.errors.engineType}/>
              )}
            </div>
        )}

        {carTransmissionData?.data?.length !== 0 && (
            <div>
              <div className="flex-col w-fullv flex w-full text-white border-b-2 border-solid border-white">
                <label
                    className="text-white/60 relatve text-[0.75em]  pointer-events-none"
                    htmlFor="type-v"
                >
                  Type de transmission
                </label>
                <select
                    name="transmissionType"
                    value={formik.values.transmissionType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full focus:outline-none  bg-transparent">
                  <option
                      hidden={true}
                      className="text-black"
                      value="0">
                    Sélectionnez un type de transmission
                  </option>
                  {carTransmissionData?.data?.map((item, index) => {
                    return (
                        <option
                            key={index}
                            className="text-black"
                            value={item?.id}>
                          {item?.label}
                        </option>
                    )
                  })}
                </select>
              </div>
              {formik.touched.transmissionType && formik.errors.transmissionType && (
                  <ErrorForm message={formik.errors.transmissionType}/>
              )}
            </div>
        )}

        {carBrandData?.data?.length !== 0 && (
            <div>
              <div className=" flex-col w-fullv flex w-full text-white border-b-2 border-solid border-white">
                <label
                    className="text-white/60 relatve text-[0.75em]  pointer-events-none"
                    htmlFor="type-v"
                >
                  Marque du véhicule
                </label>
                <select
                    name="carBrand"
                    value={formik.values.carBrand}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full focus:outline-none  bg-transparent">
                  <option
                      hidden={true}
                      className="text-black"
                      value="0">
                    Sélectionnez la marque du véhicule
                  </option>
                  {carBrandData?.data?.sort((a, b) => a.label.localeCompare(b.label)).map((item, index) => {
                    return (
                        <option
                            key={item?.id}
                            className="text-black"
                            value={item?.id}>
                          {item?.label}
                        </option>
                    )
                  })}
                </select>
              </div>
              {formik.touched.carBrand && formik.errors.carBrand && (
                  <ErrorForm message={formik.errors.carBrand}/>
              )}
            </div>
        )}

        <div className="w-full flex gap-2 items-center">
          <div className="flex-1">
            <InputFormik
              label="modèle"
              placeholder=""
              name="carModel"
              value={formik.values.carModel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
            />
            {formik.touched.carModel && formik.errors.carModel && (
              <ErrorForm message={formik.errors.carModel}/>
            )}
          </div>
          <div className="flex-1 ">
            <div className="flex-col w-full flex text-white border-b-2 border-solid border-white">
              <label
                className="text-white/60 relatve text-[0.75em]  pointer-events-none"
                htmlFor="type-v"
              >
                Couleur
              </label>
              <select
                  name="carColor"
                  value={formik.values.carColor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full focus:outline-none bg-transparent">
                <option
                    hidden={true}
                    className="text-black"
                    value="0">
                  Sélectionnez la couleur
                </option>
                {carColors.map((item, index) => {
                  return (
                    <option
                      key={index}
                      className="text-black"
                      value={item}>
                      {item}
                    </option>
                  )
                })}
              </select>
            </div>
            {formik.touched.carColor && formik.errors.carColor && (
              <ErrorForm message={formik.errors.carColor}/>
            )}
          </div>
        </div>

        <div className="w-full flex-1 mt-2">
          <InputFormik
            label="immatriculation"
            name="carImmatriculation"
            value={formik.values.carImmatriculation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder=""
            type="text"
          />
          {formik.touched.carImmatriculation && formik.errors.carImmatriculation && (
              <ErrorForm message={formik.errors.carImmatriculation}/>
          )}
        </div>

        <div className="w-full flex-1 mt-6">
          <InputFormik
            label="Date de mise en circulation"
            name="carDate"
            value={formik.values.carDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder=""
            shrink={true}
            type="date"
          />
          {formik.touched.carDate && formik.errors.carDate && (
              <ErrorForm message={formik.errors.carDate}/>
          )}
        </div>

        <div className="w-full flex-1 mt-2">
          <InputFormik
            label="Kilométrage initial"
            name="carMileage"
            value={formik.values.carMileage}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder=""
            type="number"
          />
          {formik.touched.carMileage && formik.errors.carMileage && (
            <ErrorForm message={formik.errors.carMileage}/>
          )}
        </div>

        <div className="w-full flex-1 mt-2">
          <InputFormik
            label="Quantité de carburant initial"
            name="carFuel"
            value={formik.values.carFuel}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder=""
            type="number"
          />
        </div>

        <div className="w-full flex-1 mt-2">
          <InputFormik
            label="Coût a l'achat"
            name="carPrice"
            value={formik.values.carPrice}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder=""
            type="number"
          />
          {formik.touched.carPrice && formik.errors.carPrice && (
            <ErrorForm message={formik.errors.carPrice}/>
          )}
        </div>

        <div className={["w-full hover:bg-[#2dcf34] active:bg-secondary p-2 text-center rounded my-5 text-white", loading ? "bg-gray-400" : "bg-secondary"].join(" ")}>
          <button
            className="focus:outline-none uppercase w-full"
            type="submit"
            disabled={loading}
          >
            {loading ? "chargement..." : "enregistrer"}
          </button>
        </div>
      </form>
  );
};
export default CarNewForm;
