import React from "react";

const TextAreaFormik = (props) => {
  return (
      <div className="w-full">
          <textarea
              {...props}
              rows="3"
              className="input w-full placeholder-gray-500 placeholder-opacity-0 border-b-2 border-white text-white bg-transparent focus:outline-none"
          />
          <label
              className="label absolute left-0 text-white origin-[0] transition duration-500 ease-in-out"
              htmlFor={props.label}
          >
              {props.label}
          </label>
      </div>
  );
};

export default TextAreaFormik;
