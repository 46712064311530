import React, {useContext} from "react";
import Header from "../components/header/Header";
import Nav from "./Nav";
import {SiAmazondocumentdb} from "react-icons/si";
import {SidebarContext} from "../context/SidebarContext";
import {useSelector} from "react-redux";
import {selectedCar} from "../utils";

const Main = ({children, selected}) => {
    const { toggleSidebar, handleLanguageChange, setNavBar, navBar } = useContext(SidebarContext);
    const cars = useSelector((state) => state.carManagementSlice.data);
    const car = selectedCar(cars)

    return (
        <main className="flex-1 w-full h-screen flex flex-col">
            {selected !== "empty" && <Header/>}
            {/*<Header />*/}
            {/*isSelectedCar(cars)*/}
            {(car !== null || selected === "empty") ? (
                <div className="flex-1 w-full mx-auto overflow-y-auto">
                    {children}
                </div>
            ) : (
                <div className="flex-1 flex flex-col gap-4 justify-center items-center mx-auto overflow-y-auto">
                    <SiAmazondocumentdb className="h-16 w-16 text-gray-500 object-cover"/>
                    <p className="text-gray-500 font-semibold text-sm text-center">
                        Sélectionner un véhicule pour afficher <br/> les informations
                    </p>
                    <button
                        type="button"
                        onClick={toggleSidebar}
                        className="py-3 px-5 bg-[#0D506E] hover:bg-[#f94848] font-semibold text-[white] rounded text-sm lg:hidden"
                    >
                        Sélectionner un véhicule
                    </button>
                </div>
            )}
            <Nav selected={selected}/>
        </main>
    );
};

export default Main;
