import {useEffect, useState} from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useAsync from "./useAsync";
import AuthServices from "../services/AuthServices";
import ReparationServices from "../services/ReparationServices";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectedCar} from "../utils";
import dayjs from "dayjs";
import {notifyError} from "../utils/toast";
import DocumentService from "../services/DocumentService";
import {CLIENT} from "../pref";

const useDocumentForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDocument = location?.state?.document;

    const cars = useSelector((state) => state.carManagementSlice.data);
    const car = selectedCar(cars)

    const user = useSelector((state) => state.authSlice.userInfo);

    useEffect(() => {
        if(selectedDocument === null || selectedDocument === undefined){
            navigate("/legislation")
        }else if(user?.data?.roles[0]?.name !== CLIENT){
            navigate("/legislation")
        }
    }, [selectedDocument]);

    const [loading, setLoading] = useState(false);
    const initialValues = {
        dateSub: "",
        dateExpire: "",
        cost: ""
    };

    const validationSchema = yup.object().shape({
        dateSub: yup.date().required(),
        dateExpire: yup.date().required(),
        cost: yup.number().required(),
    });

    const onSubmit = (e) => {
        setLoading(true);

        const body = {
            "vehicle_id": car?.id,
            "type": selectedDocument?.id,
            "start_date": e.dateSub,
            "end_date": e.dateExpire,
            "price": e.cost
        }

        DocumentService.addCarDocument(body)
            .then((res)=> {
                console.log(res)
                navigate(-1)
            })
            .catch((res) => notifyError(res?.response?.data?.message))
            .finally(()=> setLoading(false))
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return {
        formik,
        loading,
        navigate,
        selectedDocument,
        user
    };
};

export default useDocumentForm;
