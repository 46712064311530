import {useEffect, useState} from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {useSelector} from "react-redux";
import {selectedCar} from "../utils";
import {useLocation, useNavigate} from "react-router-dom";
import CarServices from "../services/CarServices";
import {addNewCar} from "../redux-toolkit/carManagementSlice";
import {notifyError} from "../utils/toast";
import MaintenanceServices from "../services/MaintenanceServices";
import dayjs from "dayjs";
import {CLIENT} from "../pref";

const useOperationNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedOperation = location?.state?.operation;

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const user = useSelector((state) => state.authSlice.userInfo);

  useEffect(() => {
    if(selectedOperation === null || selectedOperation === undefined){
      navigate("/")
    }else if(user?.data?.roles[0]?.name !== CLIENT){
      navigate("/repair")
    }else{
      formik.setFieldValue("date", dayjs(new Date()).format("YYYY-MM-DD"))
      formik.setFieldValue("mileage", car?.mileage.toString())
    }
  }, [selectedOperation]);

  const [loading, setLoading] = useState(false);
  const initialValues = {
    date: "",
    mileage: "",
    duration: "",
    cost: "",
    reference: "",
  };

  const validationSchema = yup.object().shape({
    date: yup.string().required("La date est obligatoire"),
    mileage: yup.number().required("Le kilométrage est obligatoire")
          .min(car?.mileage, `le kilométrage doit être supérieur ou égal à ${car?.mileage}`),
    duration: yup.number().required("La Durée de vie (en KM) est obligatoire"),
    cost: yup.number().required("Le coût de l'opération est obligatoire"),
    reference: yup
      .string()
      .required("La  Reférence du produit est obligatoire"),
  });

  const onSubmit = (e) => {
    setLoading(true);

    const body = {
      "vehicle_id": car?.id,
      "operation_type_id": selectedOperation?.operation_type?.id,
      "current_mileage": e.mileage,
      "granted_mileage": e.duration,
      "price": e.cost,
      "reference": e.reference,
      "date": e.date
    }

    MaintenanceServices.addCarOperation(body)
      .then((res)=> {
        navigate(-1)
      })
      .catch((res) => notifyError(res?.response?.data?.message))
      .finally(()=> setLoading(false))
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    loading,
  };
};

export default useOperationNew;
