import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useOnceCall} from "../utils";
import {FORGOT_PASSWORD, REGISTER, USER_INFO} from "../pref";
import AuthServices from "../services/AuthServices";
import Cookies from "js-cookie";
import {useDispatch} from "react-redux";
import {setUser} from "../redux-toolkit/authSlice";

const useRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [searchParams, setSearchParams] = useSearchParams({ type: "" });
  const phone = searchParams.get("phone")

  useOnceCall(() => {
    if (phone === undefined || typeof phone === "number" ||  phone.length === 9) {
      formik.setFieldValue('phoneNumber', phone);
      // console.log('called');
      // sendCode()
    } else {
      navigate("/");
    }
  })

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    passwordConfirmation: "",
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("Le prénom est obligatoire"),
    lastName: yup.string().required("Le nom est obligatoire"),
    // email: yup
    //   .string()
    //   .required("L'adresse e-mail est obligatoire")
    //   .email("veuillez entrer une adresse e-mail valide"),
    password: yup
      .string()
      .required("Le mot de passe est obligatoire")
      .min(8, "Le mot de passe doit avoir au moins 8 caractères"),
    passwordConfirmation: yup
      .string()
      .required("Le mot de passe de confirmation est obligatoire")
      .oneOf([yup.ref("password")], "Les mots de passes ne correspondent pas"),
    phoneNumber: yup
      .number()
      .typeError("Veuillez entrer un numéro de téléphone valide")
      .required("Le numéro de téléphone est obligatoire"),
  });

  const onSubmit = (e) => {
    setLoading(true);

    const body = {
      "first_name" : e.firstName,
      "last_name" : e.lastName,
      "phone" : e.phoneNumber,
      "password" : e.password
    };

    if(e.email){
      body["email"] = e.email
    }

    AuthServices.register(body)
      .then((res) => {
        if (res?.data?.success) {
          // notifySuccess("Login Success!");
          Cookies.set(USER_INFO, JSON.stringify(res?.data), {
            expires: 0.5,
          });
          dispatch(setUser(res?.data))
          navigate("/", { replace: true });
        } else {
          setError(res.data.message)
          // console.log(res.data.message);
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    loading,
  };
};

export default useRegister;
