import { useNavigate } from "react-router-dom";
import Main from "../../layout/Main";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectedCar} from "../../utils";
import ReparationServices from "../../services/ReparationServices";
import Skeleton from "react-loading-skeleton";

const Repair = () => {
  const navigate = useNavigate();

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const [reparationsData, setReparationsData] = useState([] || {});
  const [error, setError] = useState("");
  const [loadingReparations, setLoadingReparations] = useState(false);

  useEffect(() => {
    if(car !== null){
      loadReparation()
    }
  }, [car]);

  function loadReparation () {
    setError("")
    setLoadingReparations(true)
    ReparationServices
        .getCarReparation(car?.id)
        .then(res => setReparationsData(res?.data))
        .catch(err => setError(err.message))
        .finally(()=> setLoadingReparations(false))
  }

  return (
    <Main selected="Reparation">
      <div className="flex-1 h-full overflow-y-auto">
        <div className="flex h-full text-white justify-start flex-col px-3 md:px-6">
          <div className="flex-none text-center my-2 p-2 md:p-4 rounded-lg bg-white shadow-lg border-gray-300 border">
            <div className="uppercase text-primary font-bold text-md md:text-xl">
              element de maintenance
            </div>
            <p className="text-gray-500 text-sm md:text-base">
              Enregistrez touts les opérations de maintenance quelque soit leur
              envergure et leur cout afin d'avoir un historique des réparations
            </p>
          </div>

          {loadingReparations && (
              <div className="cards grid grid-cols-1 md:grid-cols-2 gap-6 md:px-6">
                {[1, 2].map(item => {
                  return (
                      <div key={item}>
                        <Skeleton
                            count={4}
                            height={120}
                            className="bg-gray-600"
                        />
                      </div>
                  )
                })}
              </div>
          )}

          {(!loadingReparations && error.length !== 0) && (
            <div className="h-full flex flex-col justify-center items-center text-red-500 gap-4 my-4">
              <div className="text-center">Une erreur est survenu veuillez réessayer</div>
              <button
                  onClick={loadReparation}
                  className="uppercase px-8 py-1.5 rounded hover:bg-red-500/10 active:bg-red-500/20 focus:outline-none border-2 border-red-500">
                réessayer
              </button>
            </div>
          )}

          {(!loadingReparations && error.length === 0) && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:px-6 my-4">
                {reparationsData?.data?.map(reparation => {
                  return (
                      <div
                        key={reparation?.id}
                        onClick={() => navigate("/reparation_detail", {
                          replace: false,
                          state: {
                            reparation: reparation,
                          },
                        })}
                        className="card py-2 text-[#666666]  cursor-pointer hover:bg-slate-200 rounded-lg px-2 flex  items-center "
                      >
                        <div className="w-[48px] h-[48px] md:w-[70px] md:h-[70px]">
                          <img
                              alt="ico"
                              className="w-full"
                              src={`${process.env.REACT_APP_BASE_URL}/${reparation?.thumburl}`}/>
                        </div>
                        <div className="ml-2 border-gray-300 border-b-2 w-[80%]">
                          <div className="text-[#0D506E] text-base md:text-lg font-bold">
                            {reparation?.label}
                          </div>
                          <p className="text-sm md:text-md text-gray-500">
                            {reparation?.description}
                          </p>
                        </div>
                      </div>
                  )
                })}
              </div>
          )}
        </div>
      </div>
    </Main>
);
};

export default Repair;
