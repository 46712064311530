import React from "react";
import { useNavigate } from "react-router-dom";
import Main from "../../layout/Main";
import {TbLogout} from "react-icons/tb";
import {MdOutlineMessage, MdPolicy} from "react-icons/md";
import {LuInfo} from "react-icons/lu";
import {useDispatch} from "react-redux";
import {logout} from "../../redux-toolkit/authSlice";
import Cookies from "js-cookie";
import {CLIENT, USER_INFO} from "../../pref";
import {LiaArrowLeftSolid} from "react-icons/lia";

const Setting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Main selected="empty">
      <div className="bg-white w-full h-full">
        <div className="flex  items-center py-3 bg-primary">
          <div
              onClick={() => navigate(-1)}
              className="pl-4 pr-8 cursor-pointer"
          >
            <LiaArrowLeftSolid className="text-white text-2xl"/>
          </div>
          <div className="pl-4 font-myfont text-base md:text-lg text-white">
            Paramètres
          </div>
        </div>
        <div className="bg-white flex-1 w-full h-full overflow-y-auto flex justify-center">
          <div className="w-full max-w-[500px] flex flex-col justify-center mx-4 px-4">
            <div className="logo text-center text-[55px] text-[#0D506E] cursor-pointer">
              <span className="text-bold text-[#f94848] text-[100px]">G</span>veo
            </div>
            <div className="bg-white overflow-hidden rounded-lg border">
              <a
                  target="_blank"
                  href="https://wa.me/237677703133??text=Hello! je suis ..."
                  className="flex items-center justify-start gap-6 px-3 hover:bg-gray-100 active:bg-gray-200 py-3 cursor-pointer capitalize"
              >
                <MdOutlineMessage className="text-2xl text-primary"/>
                <div className="text-sm text-gray-700">Contactez-nous</div>
              </a>
              <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.buht.gveo"
                  className="flex items-center justify-start gap-6 px-3 hover:bg-gray-100 active:bg-white py-3 cursor-pointer capitalize border-t"
              >
                <LuInfo className="text-2xl text-primary"/>
                <div className="text-sm text-gray-700">A propos de Gveo</div>
              </a>
              <a
                  target="_blank"
                  href="https://www.gveo.org/privacy"
                  className="flex items-center justify-start gap-6 px-3 hover:bg-gray-100 active:bg-white py-3 cursor-pointer capitalize border-t"
              >
                <MdPolicy className="text-2xl text-primary"/>
                <div className="text-sm text-gray-700">Politique de confidentialité</div>
              </a>
            </div>
            <button
                onClick={() => {
                  Cookies.remove(USER_INFO);
                  dispatch(logout())
                  navigate("/signin", {replace: true});
                }}
                className="mt-8 flex gap-2 items-center justify-center w-full p-3 bg-primary rounded-lg text-white hover:bg-hoverBleu-200 active:bg-primary">
              <TbLogout className="text-lg"/>
              <div>se déconnecter</div>
            </button>
          </div>
        </div>
      </div>
    </Main>
  )
};

export default Setting;
