import ErrorForm from "../form/Error";
import React from "react";
import usePhoneNumber from "../../hooks/usePhoneNumber";
import { useNavigate, useLocation } from "react-router-dom";
const FormPhone = () => {
  const { formik, loading, error, clearError } = usePhoneNumber();
  const navigate = useNavigate();

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="max-w-lg px-6 w-full flex  flex-col my-2 justify-center items-center"
    >
      <div className="w-full mt-8">
        <div className="relative box-border ">
          <input
            value={formik.values.phone}
            onChange={(e)=> {
              formik.setFieldValue('phone', e.target.value);
              clearError();
            }}
            onBlur={formik.handleBlur}
            name={"phone"}
            type="number"
            className="inputFormPrefix ml-2"
            placeholder="Numéro de téléphone"
          />
          <div className="absolute z-0 bottom-[18px] text-white flex justify-center items-center">
            <span className="">+237</span>
          </div>
        </div>
        {formik.touched.phone && formik.errors.phone && (
          <ErrorForm message={error || formik.errors.phone} />
        )}
        {error.length !== 0 && (
          <ErrorForm message={error || formik.errors.phone} />
        )}
      </div>
      <button
        disabled={loading}
        type="submit"
        className={`buttonForm my-8 focus:outline-none ${loading ? "bg-gray-300 hover:bg-gray-300 text-black" : "text-white bg-secondary hover:bg-green-600"}`}
      >
        {loading ? "Chargement..." : "CONTINUER"}
      </button>
    </form>
  );
};

export default FormPhone;
