import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormAddEmployee from "../../components/Profiles/FormAddEmployee";
import FormDocumentNew from "../../components/auth/FormDocumentNew";
import Main from "../../layout/Main";
import FormEmployeeNew from "../../components/auth/FormEmployeeNew";
import FormFuelNew from "../../components/auth/FormFuelNew";
const AddFuel = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Main selected="empty">
            <div className="flex-1 w-full h-full overflow-y-auto bg-primary flex justify-center border-t-2">
                <div className="max-w-[550px] w-full px-6 md:px-0 pb-4">
                    <div className="mt-16 text-center">
                        <div className="uppercase text-white font-bold">
                            enregistrer une carburation
                        </div>
                        <div className="lowercase text-white/50 font-bold">
                            Veuillez remplir ce formulaire
                        </div>
                    </div>
                    <div className="mt-16">
                        <FormFuelNew/>
                    </div>
                    <button
                        onClick={() => {
                            navigate(-1);
                        }}
                        className="inputSecondary"
                        type="button">
                        annuler
                    </button>
                </div>
            </div>
        </Main>
    )
}

export default AddFuel;