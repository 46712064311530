import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {selectedCar} from "../../utils";
import {useFormik} from "formik";
import * as yup from "yup";
import InputFormik from "../form/InputFormik";
import ErrorForm from "../form/Error";

const EditKmDialog = ({
    closeModal,
    onSubmit,
    loading
}) => {
    const { t } = useTranslation();

    const cars = useSelector((state) => state.carManagementSlice.data);
    const car = selectedCar(cars)
    const [mileage, setMileage] = useState(car?.mileage)

    const initialValues = {
        mileage: car?.mileage
    };

    const validationSchema = yup.object().shape({
        mileage: yup.number().required().min(car?.mileage + 1)
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    });

    return (
        <form onSubmit={formik.handleSubmit} className="gap-8 p-6">
            <div className="flex flex-col gap-2">
                <div className="font-bold text-lg">Kilométrage</div>
                <div className="text-base">Mise a jour du Kilométrage</div>
            </div>
            <div className="mt-12">
                <div className="w-full">
                    <div className="border-b-[1px]">
                        <InputFormik
                            label="mileage"
                            placeholder=""
                            name="mileage"
                            value={formik.values.mileage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            inputColor="black"
                            inputLabel="#000000"
                            type="number"
                        />
                    </div>
                    {formik.touched.mileage && formik.errors.mileage && (
                        <ErrorForm message={formik.errors.mileage}/>
                    )}
                </div>
                <div className="mt-8 flex justify-between text-lg font-semibold">
                    <button
                        onClick={closeModal}
                        type="button"
                        className="px-4 py-2 rounded focus:outline-none text-primary hover:bg-primary/20 active:bg-primary active:text-white">
                        Annuler
                    </button>
                    <button
                        disabled={loading}
                        type="submit"
                        className={["px-4 py-2 rounded focus:outline-none", loading ? "bg-transparent text-secondary hover:bg-transparent" : "text-primary hover:bg-primary/20 active:bg-primary active:text-white"].join(" ")}>
                        {loading ? "Chargement..." : "Enregistrer"}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default EditKmDialog;
