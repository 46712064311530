import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const AddToLocalStorageSideBar = localStorage.getItem('_sideBarAdd');

const SideBarMenuSlice = createSlice({
    name: 'CarManagement',
    initialState: {
        sideBarAdd: JSON.parse(AddToLocalStorageSideBar)
            ? JSON.parse(AddToLocalStorageSideBar)
            : null,
    },
    reducers: {
        ADD_SIDE_BAR_MENU: (state, action) => {
            return {
                ...state,
                sideBarAdd: action.payload
            }
        },
        EMPTY_SIDE_BAR_MENU: (state) => {
            return {
                ...state,
                sideBarAdd: null
            }
        },
    }
});

export default SideBarMenuSlice.reducer;