import {useEffect, useState} from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {useSelector} from "react-redux";
import {selectedCar} from "../utils";
import {useLocation, useNavigate} from "react-router-dom";
import {notifyError} from "../utils/toast";
import dayjs from "dayjs";
import {CLIENT} from "../pref";
import useAsync from "./useAsync";
import CarServices from "../services/CarServices";
import BreakdownServices from "../services/BreakdownServices";
import DepartureArrivalServices from "../services/DepartureArrivalServices";

const useCheckin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const selectedCheck = location?.state?.check;
  const user = useSelector((state) => state.authSlice.userInfo);

  useEffect(() => {
    if(!selectedCheck){
      navigate(-1)
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const initialValues = {
    mileage: ""
  };

  const validationSchema = yup.object().shape({
    mileage: yup.number().required("Le kilométrage est obligatoire")
        .min(car?.mileage, `le kilométrage doit être supérieur ou égal à ${car?.mileage}`),
  });

  const onSubmit = (e) => {
    setLoading(true);

    console.log("selectedCheck ", selectedCheck)

    const body = {
      "vehicle_id": car?.id,
      "type": selectedCheck?.mvt_type?.id,
      "checkin_date": dayjs(Date.now()).format("YYYY-MM-DD"),
      "mileage_in" : e.mileage
    }

    DepartureArrivalServices.addCarCheckIn(body, selectedCheck?.id)
      .then((res)=> {
        navigate(-1)
      })
      .catch((res) => notifyError(res?.response?.data?.message))
      .finally(()=> setLoading(false))
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    loading,
  };
};

export default useCheckin;
