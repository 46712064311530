import axios from "axios";
import Cookies from "js-cookie";
import {USER_INFO} from "../pref";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type' : 'application/json',
    'Cache-control' : 'no-cache'
  },
  timeoutErrorMessage: "Le serveur met trop de temps à répondre, réessayez plus tard."
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let adminInfo;
  if (Cookies.get(USER_INFO)) {
    adminInfo = JSON.parse(Cookies.get(USER_INFO));
  }

  return {
    ...config,
    headers: {
      Accept: 'application/json',
      authorization: adminInfo
        ? `Bearer ${adminInfo?.token}`
        : null
    },
  };
});

instance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      console.log("error.response.status ", error.response.status);

      if (parseInt(error.response.status) === 401) {
        console.log("try logout")
        Cookies.remove(USER_INFO);
        window.location.replace(`${process.env.REACT_APP_ADMIN_DOMAIN}/`);
      }
      return Promise.reject(error);
    }
);

const responseBody = (response) => response;

const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody),

  post: (url, body) => instance.post(url, body).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url, body) => instance.delete(url, body).then(responseBody),
};

export default requests;
