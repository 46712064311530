// Define a function to calculate the difference between two dates
import dayjs from "dayjs";

export function dateDifference(date1, date2) {
    // Parse the dates using Day.js
    const d1 = dayjs(date1);
    const d2 = dayjs(date2);

    // Calculate the difference in milliseconds
    const diffMilliseconds = d2.diff(d1);

    // Convert milliseconds to days
    const diffDays = dayjs.duration(diffMilliseconds).asDays();

    return diffDays; // return absolute difference in days
}