import React from "react";
import useDocumentForm from "../../hooks/useDocumentForm";
import Main from "../../layout/Main";
import {useNavigate} from "react-router-dom";
import FormDocumentNew from "../../components/auth/FormDocumentNew";

const DocumentNew = () => {
    const {
        formik,
        selectedDocument
    } = useDocumentForm()
    const navigate = useNavigate();

    console.log("selectedDocument ", selectedDocument)

    return(
        <Main selected="empty">
            <div className="flex-1 w-full h-full overflow-y-auto bg-primary flex justify-center border-t-2">
                <div className="max-w-[550px] w-full px-6 md:px-0 pb-4">
                    <div className="mt-16 text-center">
                        <div className="uppercase text-white font-bold">
                            enregistrer une {selectedDocument?.label}
                        </div>
                        <div className="lowercase text-white/50 font-bold">
                            {selectedDocument?.description}
                        </div>
                    </div>
                    <div className="mt-16">
                        <FormDocumentNew/>
                    </div>
                    <button
                        onClick={() => {
                            navigate(`/legislation`);
                        }}
                        className="inputSecondary"
                        type="button">
                        annuler
                    </button>
                </div>
            </div>
        </Main>
    )
}

export default DocumentNew;