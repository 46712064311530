import {useContext, useState} from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import AuthServices from "../services/AuthServices";
import {notifyError, notifySuccess} from "../utils/toast";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {SELECTED_CAR_INDEX, USER_INFO} from "../pref";
import {validateEmail} from "../utils/validation";
import {useDispatch} from "react-redux";
import {setUser} from "../redux-toolkit/authSlice";

const useLogin = () => {
  localStorage.setItem(SELECTED_CAR_INDEX, null);
  const [loading, setLoading] = useState(false);
  // const { dispatch } = useContext(AdminContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookieTimeOut = 0.5;

  const initialValues = {
    phone: "",
    password: "",
  };

  const validationSchema = yup.object({
    phone: yup.string().required("Veuillez entrer votre numéro de téléphone"),
    password: yup.string().required("Veuillez entrer votre mot de passe"),
  });

  const onSubmit = (e) => {
    const body = {
      password: e.password,
    }

    if(validateEmail(e.phone)){
      body['email'] = e.phone
    }else{
      body['phone'] = `+237${e.phone}`
    }

    setLoading(true);

    AuthServices.login(body)
      .then((res) => {
        // axios.get('https://services.gveo.org/sanctum/csrf-cookie');

        // notifySuccess("Login Success!");
        Cookies.set(USER_INFO, JSON.stringify(res?.data), {
          expires: cookieTimeOut,
        });
        dispatch(setUser(res?.data))
        navigate("/", { replace: true });
      }).catch((err) => {
        console.log(err)
        notifyError(err?.response?.data?.message);
      }).finally(()=> setLoading(false))
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    loading,
  };
};

export default useLogin;
