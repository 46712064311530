import React from "react";
import ErrorForm from "../form/Error";
import useRepairNew from "../../hooks/useRepairNew";
import InputFormik from "../form/InputFormik";
import TextAreaFormik from "../form/textareaFormik";
import useBreakdownRepair from "../../hooks/useBreakdownRepair";
import useCheckout from "../../hooks/useCheckout";
import useCheckin from "../../hooks/useCheckin";
const FormCheckin = () => {
  const {
    formik,
    loading
  } = useCheckin();

  return (
      <form onSubmit={formik.handleSubmit} className="relative flex flex-col gap-8">
          <div className="w-full">
              <InputFormik
                  label="Kilométrage a la date"
                  placeholder=""
                  name="mileage"
                  value={formik.values.mileage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
              />
              {formik.touched.mileage && formik.errors.mileage && (
                  <ErrorForm message={formik.errors.mileage}/>
              )}
          </div>

          <div
              className={["w-full active:bg-secondary p-2 text-center rounded mb-5 text-white", loading ? "bg-gray-400" : "hover:bg-[#2dcf34] bg-secondary"].join(" ")}>
              <button
                  className="focus:outline-none uppercase w-full"
                  type="submit"
                  disabled={loading}>
                  {loading ? "chargement..." : "enregistrer"}
              </button>
          </div>
      </form>
  );
};

export default FormCheckin;
