import {useEffect, useState} from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {useSelector} from "react-redux";
import {selectedCar} from "../utils";
import {useLocation, useNavigate} from "react-router-dom";
import {notifyError} from "../utils/toast";
import dayjs from "dayjs";
import {CLIENT} from "../pref";
import useAsync from "./useAsync";
import CarServices from "../services/CarServices";
import BreakdownServices from "../services/BreakdownServices";
import DepartureArrivalServices from "../services/DepartureArrivalServices";

const useCheckout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedOperation = location?.state?.operation;

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const user = useSelector((state) => state.authSlice.userInfo);

  useEffect(() => {
    formik.setFieldValue("mileage", car?.mileage.toString())
  }, []);

  const {
    loading: loadingMvtType,
    data: mvtTypeData,
    error: errorMvtType
  } = useAsync(() => CarServices.getAllCarMvtType());

  const [loading, setLoading] = useState(false);
  const initialValues = {
    type: "",
    mileage: ""
  };

  const validationSchema = yup.object().shape({
    type: yup.string().required("Veuillez sélectionner le motif de départ"),
    mileage: yup.number().required("Le kilométrage est obligatoire").min(car?.mileage, `le kilométrage doit être supérieur ou égal à ${car?.mileage}`),
  });

  const onSubmit = (e) => {
    setLoading(true);

    const body = {
      "vehicle_id": car?.id,
      "type": e.type,
      "checkout_date": dayjs(Date.now()).format("YYYY-MM-DD"),
      "mileage_out" : e.mileage
    }

    DepartureArrivalServices.addCarCheckOut(body)
      .then((res)=> {
        navigate(-1)
      })
      .catch((res) => notifyError(res?.response?.data?.message))
      .finally(()=> setLoading(false))
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    mvtTypeData,
    loadingMvtType,
    errorMvtType,
    loading,
  };
};

export default useCheckout;
