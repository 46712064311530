import {useEffect, useState} from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {selectedCar} from "../utils";
import {useNavigate} from "react-router-dom";
import MaintenanceServices from "../services/MaintenanceServices";
import {notifyError} from "../utils/toast";
import FuelServices from "../services/FuelServices";

const useFuelNew = () => {
  const navigate = useNavigate();
  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const [loading, setLoading] = useState(false);
  const initialValues = {
    station: "",
    fuel: "",
    price: "",
    Mileage: "",
  };

  const validationSchema = yup.object().shape({
    station: yup.string().required("Le nom de la station est obligatoire"),
    fuel: yup.string().required("La Quantité de carburant est obligatoire"),
    price: yup.string().required("Le prix du carburant est obligatoire"),
    mileage: yup.string().required("Le kilométrage a la date est obligatoire"),
  });

  const onSubmit = (e) => {
    setLoading(true);

    const body = {
      "vehicle_id": car.id.toString(),
      "gas_station": e.station,
      "current_mileage": e.mileage,
      "price": e.price,
      "quantity": e.fuel,
      "date": dayjs(Date.now()).format("YYYY-MM-DD")
    }

    if(car.driver != null){
      body["driver_id"] = car.driver.uuid;
    }

    console.log(body)

    FuelServices.addFuel(body)
      .then((res)=> {
        if(res.data){
          navigate(-1)
        }else{
          notifyError(res?.data?.message ?? "une erreur est survenu veuillez reessayer plutard!")
        }
      })
      .catch((res) => notifyError(res?.response?.data?.message))
      .finally(()=> setLoading(false))
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    formik.setFieldValue("mileage", car?.mileage.toString())
  }, []);

  return {
    formik,
    loading,
  };
};

export default useFuelNew;
