import React from "react";
import ErrorForm from "../form/Error";
import useRepairNew from "../../hooks/useRepairNew";
import InputFormik from "../form/InputFormik";
import TextAreaFormik from "../form/textareaFormik";
import {ErrorMessage, FieldArray, FormikProvider} from "formik";
const FormRepairNew = () => {
  const {
    formik,
    selectedReparation,
    reparationComponentList,
    loadingComponent,
    errorComponent,
    loading
  } = useRepairNew();

  return (
      <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className="relative flex flex-col gap-8">
              <div className="w-full">
                  <InputFormik
                      label="Date de la réparation"
                      placeholder=""
                      name="date"
                      value={formik.values.date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="date"
                      shrink={true}
                  />
                  {formik.touched.date && formik.errors.date && (
                      <ErrorForm message={formik.errors.date}/>
                  )}
              </div>

              <div className="w-full">
                  <InputFormik
                      label="Kilométrage a la date"
                      placeholder=""
                      name="mileage"
                      value={formik.values.mileage}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="number"
                  />
                  {formik.touched.mileage && formik.errors.mileage && (
                      <ErrorForm message={formik.errors.mileage}/>
                  )}
              </div>

              {loadingComponent && (
                  <div className="text-white/60">
                      Chargement des pièces ...
                  </div>
              )}

              {(selectedReparation !== undefined && reparationComponentList.length !== 0) && (
                  <FieldArray name="pieces">
                      {({insert, remove, push}) => (
                          formik.values.pieces.map((item, index) => {
                              return (
                                  <div key={index.toString()} className="w-full flex flex-row gap-2 items-center">
                                      <div key={index} className="flex-1 flex flex-col gap-2">
                                          <div className="rounded flex-1 flex flex-row items-center gap-3">
                                              <div className="flex-1">
                                                  <div
                                                      className="flex-col w-full flex text-white border-b-2 border-solid border-white">
                                                      <label
                                                          className="text-white/60 relatve text-[0.75em]  pointer-events-none"
                                                          htmlFor="type-v"
                                                      >
                                                          pièce
                                                      </label>
                                                      <select
                                                          value={formik.values.piece}
                                                          onChange={(e) => {
                                                              formik.setFieldValue(`pieces[${index}].piece`, e.target.value);
                                                              console.log(formik.values.pieces[index].piece)
                                                          }}
                                                          // onChange={formik.handleChange}
                                                          onBlur={formik.handleBlur}
                                                          className="w-full focus:outline-none bg-transparent"
                                                          name={`pieces[${index}].piece`}>
                                                          <option
                                                              hidden={true}
                                                              className="text-black"
                                                              value="0">
                                                              Sélectionnez une pièce
                                                          </option>
                                                          {reparationComponentList.map((item, index) => {
                                                              return (
                                                                  <option
                                                                      key={index}
                                                                      className="text-black"
                                                                      value={item?.id}>
                                                                      {item?.label}
                                                                  </option>
                                                              )
                                                          })}
                                                      </select>
                                                  </div>
                                                  <ErrorMessage
                                                      name={`pieces[${index}].piece`}
                                                      component="div"
                                                      className="field-error ml-1 mt-1 text-sm text-red-600"
                                                  />
                                              </div>
                                              <div className="flex-1 mb-1">
                                                  <div
                                                      className="flex-col w-full flex text-white border-solid border-white">
                                                      <InputFormik
                                                          label="Coût de des pièces"
                                                          placeholder=""
                                                          name={`pieces[${index}].cost`}
                                                          value={item.cost}
                                                          onChange={formik.handleChange}
                                                          onBlur={formik.handleBlur}
                                                          type="number"
                                                      />
                                                  </div>
                                                  <ErrorMessage
                                                      name={`pieces[${index}].cost`}
                                                      component="div"
                                                      className="field-error ml-1 mt-1 text-sm text-red-600"
                                                  />
                                              </div>
                                          </div>
                                          {(formik.values.pieces[index].piece === "-1" || formik.values.pieces[index].piece === "0") && (
                                              <div
                                                  className="flex-col w-full flex text-white border-solid border-white">
                                                  <InputFormik
                                                      label="Détails"
                                                      placeholder=""
                                                      name={`pieces[${index}].detail`}
                                                      value={item.detail}
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      type="text"
                                                  />
                                              </div>
                                          )}
                                      </div>
                                      <div className="flex-none">
                                          {index === 0 ? (
                                              <button
                                                  type="button"
                                                  onClick={() =>
                                                      push({
                                                          piece: "",
                                                          cost: ""
                                                      })
                                                  }
                                                  className="w-10 h-10 flex justify-center cursor-pointer text-white items-center bg-transparent hover:bg-white text-white-700 font-semibold hover:text-black border-2 border-white hover:border-transparent active:bg-white/80 rounded"
                                              >
                                                  +
                                              </button>
                                          ) : (
                                              ""
                                          )}

                                          {index > 0 ? (
                                              <button
                                                  type="button"
                                                  onClick={async () => remove(index)}
                                                  className="w-10 h-10 mt-2.5 flex justify-center cursor-pointer items-center bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white border-2 border-red-500 hover:border-transparent rounded active:bg-red-500/80"
                                              >
                                                  -
                                              </button>
                                          ) : (
                                              ""
                                          )}
                                      </div>
                                  </div>
                              )
                          })
                      )}
                  </FieldArray>
              )}

              <div className="w-full">
                  <InputFormik
                      label="Coût de la main d'oeuvre"
                      placeholder=""
                      name="costOeuvre"
                      value={formik.values.costOeuvre}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="number"
                  />
                  {formik.touched.costOeuvre && formik.errors.costOeuvre && (
                      <ErrorForm message={formik.errors.costOeuvre}/>
                  )}
              </div>

              <div className="w-full">
                  <InputFormik
                      label="Reférence du produit"
                      placeholder=""
                      name="reference"
                      value={formik.values.reference}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                  />
                  {formik.touched.reference && formik.errors.reference && (
                      <ErrorForm message={formik.errors.reference}/>
                  )}
              </div>

              <div className="w-full">
                  <TextAreaFormik
                      label="Faite un commentaire"
                      placeholder=""
                      name="comment"
                      value={formik.values.comment}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                  />
                  {formik.touched.comment && formik.errors.comment && (
                      <ErrorForm message={formik.errors.comment}/>
                  )}
              </div>

              <div
                  className={["w-full active:bg-secondary p-2 text-center rounded mb-5 text-white", loading ? "bg-gray-400" : "hover:bg-[#2dcf34] bg-secondary"].join(" ")}>
                  <button
                      className="focus:outline-none uppercase w-full"
                      type="submit"
                      disabled={loading}>
                      {loading ? "chargement..." : "enregistrer"}
                  </button>
              </div>
          </form>
      </FormikProvider>

  );
};

export default FormRepairNew;
