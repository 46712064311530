import "./App.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import SignIn from "./pages/auth/SignIn";
import Signup from "./pages/auth/Signup";
import Layout from "./layout/Layout";
import React, { useContext } from "react";
import Phone from "./pages/auth/Phone";
import ForgotPassword from "./pages/auth/ForgotPassword";
import NotFound from "./pages/404";
import PrivateRoute from "./components/login/PrivateRoute";
import Otp from "./pages/auth/Otp.js";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {CLIENT} from "./pref";
import routesAdmin from "./routes/routesAdmin";
import routesEmployee from "./routes/routesEmployee";
const duration = require("dayjs/plugin/duration");

// import {store} from "./api/store"
dayjs.extend(duration);

function App() {
  const user = useSelector((state) => state.authSlice.userInfo);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <AccessibleNavigationAnnouncer />
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/phoneNumber" element={<Phone />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/otp" element={<Otp />} />

          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Layout />}>
              {(user?.data?.roles[0]?.name === CLIENT ? routesAdmin : routesEmployee).map((route, i) => {
                return route.component ? (
                    <Route
                        key={i}
                        path={`${route.path}`}
                        element={<route.component />}
                    />
                ) : null;
              })}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
