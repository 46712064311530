import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";

const useForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    password: "",
    passwordConfirmation: "",
  };

  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Veuillez entrer votre mot de passe")
      .min(6, "Doit comporter au minimum 6 caractères")
      .max(20, "Doit comporter au moins 20 caractères"),
    passwordConfirmation: yup
      .string()
      .required("Veuillez entrer votre mot de passe")
      .min(6, "Doit comporter au minimum 6 caractères")
      .max(20, "Doit comporter au moins 20 caractères")
      .oneOf([yup.ref("password")], "Les mots de passes ne correspondent pas"),
  });

  const onSubmit = (e) => {
    console.log(e);
    setLoading(true);

    try {
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    loading,
  };
};

export default useForgotPassword;
