import requests from "./httpService";

const UtilisationServices = {
  getCarUtilisation: async (isFloat, carId, uuid, period, from, to) => {
    let url = ""

    if(isFloat){
      url = `/statistics/accounts/${uuid}?period=${period}`
    }else{
      url = `/statistics/vehicles/${carId}?period=${period}`
    }

    if (period === "custom" && from !== undefined && to !== undefined) {
      url = `${url}&to=${to}&from=${from}`;
    }

    return requests.get(url);
  },

  getCarUtilisationCost: async (carId) => {
    return requests.get(`statistics/vehicles/monthly/${carId}`);
  },
};
export default UtilisationServices;
