import ErrorForm from "../form/Error";
import React from "react";
import { useNavigate } from "react-router-dom";
import useFuelNew from "../../hooks/useFuelNew";
import InputFormik from "../form/InputFormik";

const FormFuelNew = () => {
  const navigate = useNavigate();
  const { formik, loading } = useFuelNew();
  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="relative flex flex-col gap-8">
        <div className="w-full">
          <InputFormik
              label="Nom de la station"
              placeholder=""
              name="station"
              value={formik.values.station}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
          />
          {formik.touched.station && formik.errors.station && (
              <ErrorForm message={formik.errors.station}/>
          )}
        </div>

        <div className="w-full">
          <InputFormik
              label="Quantité de carburant"
              placeholder=""
              name="fuel"
              value={formik.values.fuel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="number"
          />
          {formik.touched.fuel && formik.errors.fuel && (
              <ErrorForm message={formik.errors.fuel}/>
          )}
        </div>

        <div className="w-full">
          <InputFormik
              label="Prix du carburant"
              placeholder=""
              name="price"
              value={formik.values.price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="number"
          />
          {formik.touched.price && formik.errors.price && (
              <ErrorForm message={formik.errors.price}/>
          )}
        </div>

        <div className="w-full">
          <InputFormik
              label="Kilométrage a la date"
              placeholder=""
              name="mileage"
              value={formik.values.mileage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="number"
          />
          {formik.touched.mileage && formik.errors.mileage && (
              <ErrorForm message={formik.errors.mileage}/>
          )}
        </div>

        <div
            className={["w-full active:bg-secondary p-2 text-center rounded my-5 text-white", loading ? "bg-gray-400" : "hover:bg-[#2dcf34] bg-secondary"].join(" ")}>
          <button
              className="focus:outline-none uppercase w-full"
              type="submit"
              disabled={loading}
          >
            {loading ? "chargement..." : "enregistrer"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormFuelNew;
