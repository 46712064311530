import React from "react";
import FormPhone from "../../components/auth/FormPhone";
import usePhoneNumber from "../../hooks/usePhoneNumber";
import { REGISTER } from "../../pref";
import { useNavigate } from "react-router-dom";

const Phone = () => {
  const { type } = usePhoneNumber();
  const navigate = useNavigate();

  // console.log("type ", type);
  // localStorage.setItem("validationError", "");
  return (
    <div className="w-full   font-myfont min-h-screen py-1   flex-col  bg-primary   flex items-center">
      <div className="max-w-[1500px] w-full flex flex-col items-center  mt-10">
        <div className="max-w-[1500px] flex flex-col items-center ">
          <div className="text-md mx-6 text-white font-bold uppercase">
            {type === REGISTER ? "creer un compte" : "mot de passe oublié"}
          </div>
          <p className=" text-sm mt-1 text-white/70 font-semibold">
            Veuillez renseigner votre numéro de téléphone
          </p>
        </div>

        <FormPhone />

        <div className="max-w-lg  px-6 w-full flex flex-col items-center justify-center">
          <button
            className="outlinedButton"
            onClick={() => {
              navigate("/login");
            }}
          >
            Me Connecter
          </button>
        </div>
      </div>
    </div>
  );
};

export default Phone;
