import React from "react";
import { useTranslation } from "react-i18next";
import { FORGOT_PASSWORD, REGISTER } from "../../pref";
import LoginForm from "../../components/auth/LoginForm";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-full font-myfont min-h-screen py-1  flex-col bg-primary flex items-center">
      <div className="max-w-[1500px] w-full flex flex-col justify-center items-center mt-10">
        <div className="max-w-[1500px] flex flex-col items-center ">
          <div className="text-md  mx-6 text-white font-bold">CONNEXION</div>
          <p className=" text-sm mt-1 text-white opacity-60 font-bold">
            connectez vous à votre compte gveo
          </p>
        </div>
        <LoginForm />
        <div className="max-w-lg  px-6 w-full flex flex-col items-center justify-center">
          <button
            type="button"
            onClick={() => {
              navigate(`/phoneNumber?type=${FORGOT_PASSWORD}`);
            }}
            className="textButtonForm my-8"
          >
            MOT DE PASSE OUBLIÉ
          </button>
          <button
            type="button"
            className="outlinedButton"
            onClick={() => {
              navigate(`/phoneNumber?type=${REGISTER}`);
            }}
          >
            CRÉER MON COMPTE
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
