import {useContext, useEffect, useState} from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FORGOT_PASSWORD, REGISTER} from "../pref";
import AuthServices from "../services/AuthServices";
import {useOnceCall} from "../utils";
const useOtp = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState(["", "", "", "", "", ""]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [isSend, setIsSend] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams({ type: "" });
  const phone = searchParams.get("phone")
  const type = searchParams.get("type")

  useOnceCall(() => {
    if (searchParams.get("type") === REGISTER || searchParams.get("type") === FORGOT_PASSWORD) {
      // console.log('called');
      sendCode()
    } else {
      navigate("/");
    }
  })

  const handleCode = (value) => {
    if(error){
      setError("")
    }
    setCode(value)
  };

  const codeValidator = () => {
    if (code.join("") && code.join("").length === 6) {
      verifyCode(code.join(""));
      // navigate("/");
    } else {
      setError("Tout les champs doivent etre remplit");
    }
  };

  const sendCode = (code) => {
    setLoading(true);

    const body = {
      'phone': `+237${phone}`,
    };

    AuthServices.sendOtp(body)
        .then((res) => {
          console.log(res?.data)
          // if (res?.data?.success) {
          //   console.log(res.data.message, e.phone);
          //   navigate(`/otp?type=${REGISTER}&phone=${e.phone}`);
          // } else {
          //   setError(res.data.message)
          //   // console.log(res.data.message);
          // }
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
  };

  const verifyCode = (code) => {
    setLoading(true);

    const body = {
      'phone': `+237${phone}`,
      'otp': code
    };

    AuthServices.verifyOtp(body)
      .then((res) => {
        // console.log(res?.data)
        if (res?.data?.success) {
          if(type === REGISTER){
            navigate(`/signup?type=${REGISTER}&phone=${phone}`);
          }else{
            navigate(`/ForgotPassword?type=${FORGOT_PASSWORD}&phone=${phone}`);
          }
        } else {
          setError(res.data.message)
          // console.log(res.data.message);
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  return {
    code,
    type,
    phone,
    error,
    handleCode,
    codeValidator
  };
};

export default useOtp;
