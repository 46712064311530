import ErrorForm from "../form/Error";
import React from "react";
import { useNavigate } from "react-router-dom";
import useOperationNew from "../../hooks/useOperationNew";
import InputFormik from "../form/InputFormik";

const FormOperationNew = () => {
  const navigate = useNavigate();
  const { formik, loading } = useOperationNew();

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="relative flex flex-col gap-8">
        <div className="w-full">
          <InputFormik
            label="Date de l'opération"
            placeholder=""
            name="date"
            value={formik.values.date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.date && formik.errors.date}
            helperText={formik.touched.date && formik.errors.date}
            type="date"
            shrink={true}
          />
          {/*{formik.touched.date && formik.errors.date && (*/}
          {/*  <ErrorForm message={formik.errors.date}/>*/}
          {/*)}*/}
        </div>

        <div className="w-full">
          <InputFormik
            label="Kilométrage a la date"
            placeholder=""
            name="mileage"
            value={formik.values.mileage}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.mileage && formik.errors.mileage}
            helperText={formik.touched.mileage && formik.errors.mileage}
            type="number"
          />
          {/*{formik.touched.mileage && formik.errors.mileage && (*/}
          {/*  <ErrorForm message={formik.errors.mileage}/>*/}
          {/*)}*/}
        </div>

        <div className="w-full">
          <InputFormik
            label="Duration de vie (en KM)"
            placeholder=""
            name="duration"
            value={formik.values.duration}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.duration && formik.errors.duration}
            helperText={formik.touched.duration && formik.errors.duration}
            type="number"
          />
          {/*{formik.touched.duration && formik.errors.duration && (*/}
          {/*  <ErrorForm message={formik.errors.duration}/>*/}
          {/*)}*/}
        </div>

        <div className="w-full">
          <InputFormik
            label="Cout de l'opération"
            placeholder=""
            name="cost"
            value={formik.values.cost}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.cost && formik.errors.cost}
            helperText={formik.touched.cost && formik.errors.cost}
            type="number"
          />
          {/*{formik.touched.cost && formik.errors.cost && (*/}
          {/*  <ErrorForm message={formik.errors.cost}/>*/}
          {/*)}*/}
        </div>

        <div className="w-full">
          <InputFormik
            label="Réference de produit"
            placeholder=""
            name="reference"
            value={formik.values.reference}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.reference && formik.errors.reference}
            helperText={formik.touched.reference && formik.errors.reference}
            type="text"
          />
          {/*{formik.touched.reference && formik.errors.reference && (*/}
          {/*  <ErrorForm message={formik.errors.reference}/>*/}
          {/*)}*/}
        </div>

        <div
            className={["w-full active:bg-secondary p-2 text-center rounded my-5 text-white", loading ? "bg-gray-400" : "hover:bg-[#2dcf34] bg-secondary"].join(" ")}>
          <button
            className="focus:outline-none uppercase w-full"
            type="submit"
            disabled={loading}
          >
            {loading ? "chargement..." : "enregistrer"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormOperationNew;
