import ErrorForm from "../form/Error";
import React from "react"
import { useNavigate } from "react-router-dom";
import InputFormik from "../form/InputFormik";
import useBreakdownNew from "../../hooks/useBreakdownNew";

const FormBreakdownNew = () => {
  const {
    loading,
    componentData,
    systemComponentData,
    loadingSystemComponent,
    formik
  } = useBreakdownNew();

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="relative flex flex-col gap-8">
        {componentData?.data?.length !== 0 && (
            <div>
              <div className="flex-col w-full flex text-white border-b-2 border-solid border-white">
                <label
                    className="text-white/60 relatve text-[0.75em]  pointer-events-none"
                    htmlFor="type-v"
                >
                  Composant
                </label>
                <select
                    value={formik.values.component}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full focus:outline-none  bg-transparent"
                    name="component">
                  <option
                      hidden={true}
                      className="text-black"
                      value="0">
                    Sélectionnez le composant
                  </option>
                  {componentData?.data?.map((item, index) => {
                    return (
                      <option
                        key={index}
                        className="text-black"
                        value={item?.id}>
                        {item?.label}
                      </option>
                    )
                  })}
                </select>
              </div>
              {formik.touched.component && formik.errors.component && (
                  <ErrorForm message={formik.errors.component}/>
              )}
            </div>
        )}

        {loadingSystemComponent && (
            <div className="text-white/60">
              Chargement des pièces ...
            </div>
        )}

        {systemComponentData.length !== 0 && (
            <div>
              <div className="flex-col w-full flex text-white border-b-2 border-solid border-white">
                <label
                    className="text-white/60 relatve text-[0.75em]  pointer-events-none"
                    htmlFor="type-v"
                >
                  Pièces
                </label>
                <select
                    value={formik.values.systemComponent}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full focus:outline-none  bg-transparent"
                    name="systemComponent">
                  <option
                      hidden={true}
                      className="text-black"
                      value="0">
                    Sélectionnez le composant
                  </option>
                  {systemComponentData?.map((item, index) => {
                    return (
                        <option
                            key={index}
                            className="text-black"
                            value={item?.id}>
                          {item?.label}
                        </option>
                    )
                  })}
                </select>
              </div>
              {formik.touched.systemComponent && formik.errors.systemComponent && (
                  <ErrorForm message={formik.errors.systemComponent}/>
              )}
            </div>
        )}

        <div
            className={["w-full active:bg-secondary p-2 text-center rounded my-5 text-white", loading ? "bg-gray-400" : "hover:bg-[#2dcf34] bg-secondary"].join(" ")}>
          <button
            className="focus:outline-none uppercase w-full"
            type="submit"
            disabled={loading}
          >
            {loading ? "chargement..." : "enregistrer"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormBreakdownNew;
